import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjectiveDto } from '@ims-shared/dto/config.dto';

@Component({
  selector: 'app-objective-delete-dialog',
  templateUrl: './objective-delete-dialog.component.html',
  styleUrls: ['./objective-delete-dialog.component.css']
})
export class ObjectiveDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ObjectiveDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { objective: ObjectiveDto }
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
} 