import { BookingDetailService, BookingDetailsFormData, SearchShowHideBoolean, bookingStatusValidation } from './booking-detail.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { BookingStatus } from '@ims-shared/enum/booking-status';
import { startWith, forkJoin, map, Observable, of, Subject, takeUntil, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { bookingDateValidation, CustomErrorStateMatcher } from '../booking/booking.util';
import { MatTableDataSource } from '@angular/material/table';
import { BookingDetailsInquiryDto, DistinctBookingByColsListItemDto } from '@ims-shared/dto/booking.dto';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DailyDetailDialogComponent } from './daily-detail-dialog/daily-detail-dialog.component';
import { ReferenceDto } from '@ims-shared/dto/reference.dto';
import { BookingService } from '../_service/booking.service';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UserRole } from '@ims-shared/enum/user-role';
import { getDbBookingStatusEnum } from '@ims-shared/utils/enum.util';
import { PlacementService } from '../_service/placement.service';
import { Placement, PlacementMap } from '@ims-shared/dto/placements.dto';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.css']
})
export class BookingDetailComponent implements OnInit{
  
  userTeam: string = '';
  userRole?: UserRole;

  deviceTypes!: ReferenceDto[];
  adSizeList!: ReferenceDto[];
  channels!: ReferenceDto[];
  placementMap!: PlacementMap

  bookingStatusEnum = getDbBookingStatusEnum()

  form = this.fb.group({
    projectCode: new FormControl<number| undefined>(undefined, [Validators.pattern('^[0-9]*$'), Validators.min(3001)]),
    bookingName: new FormControl<string| DistinctBookingByColsListItemDto>(''),
    bookingStartDate: new FormControl(new Date(), [Validators.required]),
    bookingEndDate: new FormControl(new Date(), [Validators.required]),
    format: new FormControl<(ReferenceDto)[]| undefined>(undefined),
    device: new FormControl<(ReferenceDto)[]| undefined>(undefined),
    channel: new FormControl<(ReferenceDto)[]| undefined>(undefined),
    bookingStatus: new FormControl([this.bookingStatusEnum[BookingStatus.PENDING_TM_RESPONSE]]),
    advertiserName: new FormControl<string[]| DistinctBookingByColsListItemDto[]>([])
  }, { validators: [bookingDateValidation as ValidatorFn, bookingStatusValidation as ValidatorFn] });

  bookingDateLimit = {
    minStart: new Date(),
    minEnd: new Date(),
  }

  showHideControl!: SearchShowHideBoolean
  
  displayedColumns!: string[]
  fullDisplayColumns: string[] = ['projectCode', 'advertiserName', 'bookingName', 'startDate', 'endDate', 'duration', 'format', 'device', 'channel', 'requestedInventory', 'createdBy', 'bookingStatus', 'phase','actions']; 
  semiFullDisplayColumns: string[] = ['projectCode', 'advertiserName', 'bookingName', 'startDate', 'endDate', 'duration', 'requestedInventory', 'createdBy', 'bookingStatus', 'phase','actions']; 
  dynamicDisplayedColumns: string[] = ['projectCode', 'advertiserName', 'bookingName', 'startDate', 'endDate', 'requestedInventory', 'bookingStatus', 'phase', 'actions']; 
  minimalColumns: string[] = ['projectCode', 'bookingName', 'bookingStatus', 'startDate', 'endDate', 'actions'];
  dataSource = new MatTableDataSource<BookingDetailsInquiryDto>();
  
  bookingNameOptions!: DistinctBookingByColsListItemDto[];
  advertiserNameListDto!: DistinctBookingByColsListItemDto[];
  isSearched = false
  // dailyDetailDataSource = new MatTableDataSource<BookingDetailsInquiryDto>();

  private destroy$ = new Subject<void>();
  private readonly SANITIZE_PATTERNS = {
    HTML_TAGS: /<[^>]*>/g,
    SPECIAL_CHARS: /[&<>"']/g,
    NON_DIGITS: /[^\d]/g
  } as const;

  matcher = new CustomErrorStateMatcher();

  @ViewChildren('errorLabel') 
  matErrors!: QueryList<ElementRef>;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  
  get formAdvertiserNameArray() {
    return this.form.get('advertiserName') as FormArray
  }

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private bookDetailService: BookingDetailService,
    private bookingService: BookingService,
    private placementService: PlacementService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this._initiateUserRoleAndAccessCheck()
    this.isSearched = false
  }

  isMobile: boolean = window.innerWidth < 600;
  
  @HostListener('window:resize', ['$event'])
  onMobile(event: Event) {
    this.isMobile = window.innerWidth < 600;
  }

  ngOnInit(): void {
    this.initiateForm() 
    this.retrievePlacementsAndReferencesAndControls();
    this.observeBreakpointChanges();
    this.observebookingNameChange();
  }
  

  initiateForm(): void {
    this.bookDetailService.resetShowHideControl(); // reset err msg
    this.bookDetailService._showHideControl$.subscribe(control => {
      this.showHideControl = control
    })
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear() ;
    const day = today.getDate();
    this.form.get("bookingStartDate")!.setValue(new Date(year, month, day, 0, 0))
    this.form.get("bookingEndDate")!.setValue(new Date(year, month, day, 23, 59))
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      // console.log("form value: ", value)
      this.updatebookingDateLimit(this.form.get("bookingStartDate")!.value, this.form.get("bookingEndDate")!.value)
    });

  }

  _initiateUserRoleAndAccessCheck(): void {
    this.authService.getUserInfo().subscribe({
      next:(user) => {
        this.userTeam = user.team;
        this.userRole = user.role
      },
      error: (error) => {
        console.error(`error in _initiateUserRoleAndAccessCheck(): ${error}`)
      }
    })
  }
  

  retrievePlacementsAndReferencesAndControls() {
    const placementList$ = this.placementService.getPlacements();
    const references$ = this.bookingService.findAllReferences();
    const advertiserNames$ = this.bookingService.findAllDistinctBookingByCols("advertiserName");
    const previousResults$ = this.bookDetailService.getSearchResults();
    const previousPaginatorDetail$ = this.bookDetailService.getpreviousPaginator();
    const previousFilterFormValue$ = this.bookDetailService.getpreviousFilterFormValue();
    forkJoin([placementList$, references$, advertiserNames$, previousResults$, previousPaginatorDetail$, previousFilterFormValue$]).subscribe(([adSizePlacements, references, advertiserNames, previousResults, previousPaginatorDetail, previousFilterFormValue]) => {
      this.deviceTypes = references.filter(reference => reference.refType === "device");
      this.adSizeList = references.filter(reference => reference.refType === "format");
      this.channels = references.filter(reference => reference.refType === "channel");
      this.placementMap = adSizePlacements
      this.advertiserNameListDto = advertiserNames.sort((a, b) => {
        const nameA = a.advertiserName ?? '';
        const nameB = b.advertiserName ?? '';
        return nameA.localeCompare(nameB);
      });

      if (previousResults.length > 0 && previousFilterFormValue) {
        this.bookDetailService.updateShowHideControl({
          isError: false,
          noResult: false,
          showDetailTable: true
        })
        setTimeout(() => {
          this.form.patchValue({
            format: previousFilterFormValue?.format,
            device: previousFilterFormValue?.device,
            channel: previousFilterFormValue?.channel,
            bookingName: previousFilterFormValue?.bookingName,
            bookingStartDate: new Date (previousFilterFormValue.bookingStartDate),
            bookingEndDate: new Date(previousFilterFormValue.bookingEndDate),
            bookingStatus: previousFilterFormValue.bookingStatus,
            advertiserName: previousFilterFormValue?.advertiserName,
            projectCode: previousFilterFormValue?.projectCode
          });
          this.dataSource.data = previousResults;
          // console.log(`previousPaginatorDetail: ${JSON.stringify(previousPaginatorDetail)}`)
          this.paginator.pageSize = previousPaginatorDetail.pageSize;
          this.paginator.pageIndex = previousPaginatorDetail.pageIndex;
          this.paginator.length = previousPaginatorDetail.length;
          this.dataSource.paginator = this.paginator;
        });
      }
      else{
        // console.log('advertiserNameListDto', this.advertiserNameListDto)
        this.form.patchValue({
          format: [this.adSizeList[0]],
          device: [this.deviceTypes[0]],
          channel: [this.channels[0]]
        })
      }
      // console.log(`placementList: ${JSON.stringify(this.placementMap)}`)
    })
  }

  onEndDateChange(event: any) {
    const endDate: any = this.form.get("bookingEndDate")!.value
    if (endDate instanceof Date){
      const month = endDate!.getMonth();
      const year = endDate!.getFullYear() ;
      const day = endDate!.getDate();
      this.form.get("bookingEndDate")!.setValue(new Date(year, month, day, 23, 59))
    }
  }

  updatebookingDateLimit(form_start: string| Date| null, form_end: string| Date| null){
    if (form_start && form_start instanceof Date){
      const today = new Date(form_start);
      this.bookingDateLimit.minEnd = today
    }
    if (form_start && form_end){
      if (form_start > form_end) {
        this.form.get("bookingEndDate")!.setValue(null)
        this.form.updateValueAndValidity();
      }
    }
  }

  observeBreakpointChanges() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.displayedColumns = this.minimalColumns;
        }
        else if (result.breakpoints[Breakpoints.Small]) {
          this.displayedColumns = this.dynamicDisplayedColumns;
        } 
        else if (result.breakpoints[Breakpoints.Medium]) {
          this.displayedColumns = this.semiFullDisplayColumns;
        } else {
          this.displayedColumns = this.fullDisplayColumns;
        }
      }
    });
  }

  exe_search_scrollTop() {
    setTimeout(() => {
      const errorElement = this.matErrors.find(error => error.nativeElement !== null);
      if (errorElement) {
        errorElement.nativeElement.parentElement.scrollIntoView({ behavior: "smooth", block: "center"});
      }
    });
  }


  exe_search() {
    this.form.get('projectCode')?.setErrors(null); //Note: value is sanitizedValue by onNumberInput()
    this.isSearched = true
    if (this.form.invalid) {
      this.exe_search_scrollTop();
      // const error = this.collectErrors(this.form);
      // console.log(error)
    } 
    this.bookDetailService.updateShowHideControl({
      isError: false,
      noResult: false,
      showDetailTable: false
    })
    Object.keys(this.form.controls).map(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    this.form.updateValueAndValidity()
    const formData= this.form.value
    if(this.form.valid){
      this.bookDetailService.updateShowHideControl({
        disableSearchBtn: true,
        disableExportBtn: true,
      })
      
      // console.log('exe_search body:', formData);
      this.bookDetailService.findBookingDetail((formData as BookingDetailsFormData ))
        .subscribe({
          next:(res: BookingDetailsInquiryDto[]| []) => {
            if (res.length > 0){
              this.bookDetailService.updateShowHideControl({
                showDetailTable: true
              })
              this.bookDetailService.setSearchResults(res)
              this.bookDetailService.setPreviousFilter(formData as BookingDetailsFormData)
              this.dataSource.data = res;
              setTimeout(() => {
                this.dataSource.paginator = this.paginator
                this.bookDetailService.setPreviousPaginator(this.paginator)
              } );
            }
            else{
              this.bookDetailService.updateShowHideControl({
                showDetailTable: false,
                noResult: true
              })
            }
          },
          error: (error) => {
            console.error(`Error in findBookingDetail function!: ${error}`)
            this.bookDetailService.updateShowHideControl({
              disableSearchBtn: false,
              disableExportBtn: false,
              isError: true
            })
          },complete: () => {
            this.bookDetailService.updateShowHideControl({
              disableSearchBtn: false,
              disableExportBtn: false,
            })
            // console.log(`Finish findBookingDetail function!`)
          }
        });
    }
  }

  exe_export() {
    if (this.form.invalid) {
      this.exe_search_scrollTop();
    } 
    
    Object.keys(this.form.controls).map(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    this.form.updateValueAndValidity() //validate the form again for error labels scrolltop
    const formData = this.form.value
    if(this.form.valid){
      this.bookDetailService.updateShowHideControl({
        disableSearchBtn: true,
        disableExportBtn: true,
      })
      
      // console.log('exe_export body:', formData);
      this.bookDetailService.exportBookingDetailCsv((formData as BookingDetailsFormData ))
        .subscribe({
          next:(blob) => {
            try {
              if (blob.size > 0) {
                const timestamp = moment().valueOf();
                saveAs(blob, 'booking-details-'+ timestamp +'.csv');
              } else {
                this.bookDetailService.updateShowHideControl({
                  showDetailTable: false,
                  noResult: true
                })
              }
            } catch (error) {
              console.error('Error in saveAs() of exe_export():', error);
            }
          },
          error: (error) => {
            console.error('Error in exportBookingDetailCsv():', error);
            this.bookDetailService.updateShowHideControl({
              showDetailTable: false,
              isError: true
            })
          },complete: () => {
            this.bookDetailService.updateShowHideControl({
              disableSearchBtn: false,
              disableExportBtn: false,
            })
            // console.log(`Finish exe_export function!`)
          }
        });
    }
  }

  getDailyDetail(bookingDetail: BookingDetailsInquiryDto) {
    this.bookDetailService.findBookingDetailDaily(bookingDetail.bookingDetailId)
      .subscribe({
        next:(res) => {
          // console.log(`res from findBookingDetailDaily in getDailyDetail: ${res}`)
          if (res.length > 0){
            this.bookDetailService.updateShowHideControl({
              showDetailTable: true
            })
            const dialogRef = this.dialog.open(DailyDetailDialogComponent, {
              width: '650px',
              data: {
                dataSource: res,
                format: bookingDetail.format,
                device: bookingDetail.device,
                channel: bookingDetail.channel
              }
            });
          }
        },
        error: (error) => {
          
        },complete: () => {
          // console.log(`Finish getDailyDetail function!`)
        }
      });
  }

  isInnerWidth1150() {
    return window.innerWidth < 1150
  }

  getbookingStatusEnum(status: BookingStatus){
    return this.bookingStatusEnum[status as keyof typeof BookingStatus]
  }

  editBooking(id: number) {
    // console.log(`editBooking: `, id);
    this.bookDetailService.setPreviousPaginator(this.paginator)
    this.router.navigate(['/booking/', id], { queryParams: { readonly: false }})
  }

  disableEditBtnCheck(bookingDetail: BookingDetailsInquiryDto){
    if (this.userRole === undefined){
      return true
    }
    if (bookingDetail.booking.status === BookingStatus.PENDING_TM_RESPONSE || bookingDetail.booking.status === BookingStatus.PENDING_TM_RESPONSE_CONFIRMATION || bookingDetail.booking.status === BookingStatus.PENDING_APPROVAL){
      return false
    }
    else if (bookingDetail.booking.status === BookingStatus.APPROVED){
      if (this.userRole === UserRole.SALES || this.userRole === UserRole.SALES_ADMIN ){
        return true
      }
      return false
    }
    else if (bookingDetail.booking.status === BookingStatus.CANCELLED || bookingDetail.booking.status === BookingStatus.CLOSED){
      return true
    }
    return true
  }

  showFormValueRefToItsRefName(type: string) {
    return this.form.get(type)?.value ? (this.form.get(type)?.value).map((item: ReferenceDto| undefined) => item?.refName ?? ''): '';
  }

  compareReferences(reference1: ReferenceDto, reference2: ReferenceDto): boolean {
    return reference1 && reference2 ? reference1.refId === reference2.refId && reference1.refName === reference2.refName : reference1 === reference2;
  }

  private isMatchingReference(ref1: ReferenceDto, ref2: ReferenceDto): boolean {
    return ref1.refType === ref2.refType && ref1.refId === ref2.refId && ref1.refName === ref2.refName && ref1.channelPlatform === ref2.channelPlatform;
  }

  placementMapOpts(type: string, option: ReferenceDto): boolean {
    const formats = this.form.get('format')!.value;
    if (!formats?.length || !formats) {
      return true
    }
    const devices = this.form.get('device')!.value
    if (!devices?.length && type != 'device') return true;
    const placements = this.getPlacementsForFormats(formats);

    if (placements){
      for (const placement of placements) {
        if (type === "device" && this.isMatchingReference(placement.device, option)) {
          return false;
        }
        if (type === "channel" && this.isChannelOptionValid(placement, option, placements)) {
          return false;
        }
      }
    }
    return true;
  }

  private getPlacementsForFormats(formats: (ReferenceDto | undefined)[]): Placement[]{
    return formats.flatMap(format => this.placementMap[format!.refName]);
  }

  private isChannelOptionValid(placement: Placement, option: ReferenceDto, placements: Placement[]): boolean {
    let devices = this.form.get('device')!.value;
    if (devices?.length) {
      const selectedDeviceNames = new Set(devices.map(device => device!.refName)) //new Set(devices.map(device => device.refName));
      const availablePlacement = placements.filter(p => selectedDeviceNames.has(p.device.refName));// p.device.refName === selectedDeviceNames
      const availableChannels = new Set(availablePlacement.map(p => p.channel.refName));
      return availableChannels.has(placement.channel.refName) && this.isMatchingReference(placement.channel, option);
    }
    return true
  }

  placementMapOptsChange(type: string): void {
    const selects = this.form.get(type)!.value;
    // console.log("%s value before placementMapOptsChange: %O", type, selects);
    const updateArr = this.getUpdateArrayOnPlacementMapOptsChange(type);
    // console.log("%s !selects?.length: ", type, !selects?.length);
    // console.log("%s selects?.length: ", type, selects?.length);
    // console.log("isArray", Array.isArray(selects));
    if (!selects?.length) {
      updateArr.forEach(item => {
        this.form.get(item)!.setValue(undefined);
      })
      // console.log("%s value after placementMapOptsChange: format: %O, device: %O, channel: %O", type, this.form.get('format')!.value, this.form.get('device')!.value, this.form.get('channel')!.value);
      return ;
    }
    const formats = this.form.get('format')!.value
    if (!formats?.length) return;
    const placements = this.getPlacementsForFormats(formats as ReferenceDto[]);

    if (type === "format") {
      this.updateDeviceOptions(placements);
    }
    if (type !== "channel"){
      this.updateChannelOptions(placements);
    }
  }

  private updateDeviceOptions(placements: Placement[]): void {
    const availableDevices = new Set(placements.map(placement => placement.device.refName));
    const validDevices = this.deviceTypes.filter(device => availableDevices.has(device.refName));
    this.form.get('device')?.setValue(validDevices[0] ? [validDevices[0]] : undefined, { emitEvent: false });
    // this.form.get('device')?.setValue(validDevices[0], { emitEvent: false });
  }

  private updateChannelOptions(placements: Placement[]): void {
    const devices = this.form.get('device')!.value;
    let validChannels: ReferenceDto[] = [];
    if (devices?.length) {
      const selectedDeviceNames = new Set(devices.map(device => device!.refName)); // devices.refName
      const availablePlacement = placements.filter(placement => selectedDeviceNames.has(placement.device.refName)); // placement.device.refName === selectedDeviceNames
      const availableChannels = new Set(availablePlacement.map(placement => placement.channel.refName));
      validChannels = this.channels.filter(channel => availableChannels.has(channel.refName));
    } else {
      const availableChannels = new Set(placements.map(placement => placement.channel.refName));
      validChannels = this.channels.filter(channel => availableChannels.has(channel.refName));
    }
    this.form.get('channel')?.setValue(validChannels[0] ? [validChannels[0]] : undefined, { emitEvent: false });
    // console.log(`this.form.get('channel')?.value in updateChannelOptions: ${JSON.stringify(this.form.get('channel')?.value)}`)
  }

  getUpdateArrayOnPlacementMapOptsChange(type: string): string[] {
    switch (type) {
      case 'format':
        return ['format', 'device', 'channel'];
      case 'device':
        return ['device', 'channel'];
      case 'channel':
        return ['channel'];
      default:
        return [];
    }
  }

  observebookingNameChange(){
    this.form.get('bookingName')!.valueChanges.pipe(
      startWith(''),
      debounceTime(1500),
      distinctUntilChanged(),
      switchMap(value => {
        return this.filterBookingNameOptions(value)
      })
    ).subscribe(value => {
      this.bookingNameOptions = value
    })
  }

  filterBookingNameOptions(value: string | DistinctBookingByColsListItemDto | null) {
    // console.log('value in filterBookingNameOptions', value)
    if (!value || typeof value !== 'string') {
      return of([]) 
    }
    const input = value.trim().toLowerCase();
    return this.bookingService.findBookingByColsWithLikeString("bookingName", "bookingName", input)
  }

  displayBookingName(bookingName: DistinctBookingByColsListItemDto| string): string {
    return bookingName && typeof bookingName === 'string' ? bookingName : (bookingName as DistinctBookingByColsListItemDto).bookingName ;
  }

  onPageChange(event: PageEvent) {
    // console.log(`onPageChange() before , event: ${JSON.stringify(event)}, previousPageIndex: ${this.previousPageIndex}, previousPageSize: ${this.previousPageSize}`);
    
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.bookDetailService.setPreviousPaginator(this.paginator)
    // console.log(`onPageChange() after , event: ${JSON.stringify(event)}, previousPageIndex: ${this.previousPageIndex}, previousPageSize: ${this.previousPageSize}`);
  }

  onNumberInput(event: Event) {
    const input = (event.target as HTMLInputElement)
    const sanitizedValue = this.sanitizeInput(input.value);

    const projectCodeControl = this.form.get('projectCode');
    const numericValue = sanitizedValue ? Number(sanitizedValue) : null;

    if (input.value.trim() !== sanitizedValue) {
      input.value = sanitizedValue;
      projectCodeControl?.setValue(numericValue);
      projectCodeControl?.setErrors({ invalidNumber: true });
      projectCodeControl?.markAsTouched();
    }
    else{
      // Note: Firefox browser still can input string 
      // even set type="number" for the input field
      const numValue = sanitizedValue ? Number(sanitizedValue) : undefined;
      projectCodeControl?.setValue(numValue);
      
      const currentErrors = projectCodeControl?.errors || {};
      delete currentErrors['invalidNumber'];
      
      if (Object.keys(currentErrors).length > 0) {
        projectCodeControl?.setErrors(currentErrors);
      } else {
        projectCodeControl?.setErrors(null);
      }
    }
  }

  private sanitizeInput(value: string): string {
    return value.trim()
        .replace(this.SANITIZE_PATTERNS.HTML_TAGS, '')
        .replace(this.SANITIZE_PATTERNS.SPECIAL_CHARS, '')
        .replace(this.SANITIZE_PATTERNS.NON_DIGITS, '');
  }
}
