<h2>Update Contact</h2>
<mat-dialog-content class="dialog-content">
  <form [formGroup]="extensionForm">
    <mat-form-field appearance="outline" class="full-width">
      <input matInput formControlName="extension" type="text" placeholder="Enter your extension number">
      <mat-error *ngIf="extensionForm.get('extension')?.hasError('required')">
        Extension number is required
      </mat-error>
      <mat-error *ngIf="extensionForm.get('extension')?.hasError('pattern') || extensionForm.get('extension')?.hasError('invalidLength')">
        invalid format
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()" [disabled]="isSubmitting">Cancel</button>
  <button 
    mat-raised-button 
    color="primary" 
    (click)="onSubmit()" 
    [disabled]="extensionForm.invalid || isSubmitting">
    <mat-spinner *ngIf="isSubmitting" diameter="20" class="button-spinner"></mat-spinner>
    <span *ngIf="!isSubmitting">Save</span>
  </button>
</mat-dialog-actions>
