<div class="container">
    <form [formGroup]="form" class="form">
        <!-- basic settings -->
        <div class="card">
            <h3>Booking Detail Inquiry</h3>
            <mat-divider></mat-divider>
            <div class="card-body">
                <div class="grid"> 
                    <div class="grid-body">
                        <h4 class="font-bold">Direct Search</h4>
                        <div [class.d-flex]="!isMobile">
                            <div class="form-field-container">
                                <label>Project Code</label>
                                <mat-form-field class="searchbox-form-field">
                                    <span matTextPrefix>TM-AD-</span>
                                    <input matInput
                                        inputmode="numeric"
                                        pattern="[0-9]*"
                                        class="placeholder-text"
                                        aria-label="Project Code" 
                                        name="projectCode"
                                        formControlName="projectCode"
                                        (input)="onNumberInput($event)"
                                    />
                                    <mat-error *ngIf="form.get('projectCode')?.errors?.['invalidNumber']">
                                        Please enter numbers only
                                    </mat-error>
                                    <mat-error *ngIf="form.get('projectCode')?.errors?.['min'] && !form.get('projectCode')?.errors?.['invalidNumber']">
                                        Project code must be at least 3001
                                    </mat-error>
                                </mat-form-field>
                            </div>
            
                            <div class="form-field-container" [class.w-85percent]="!isMobile" [class.ml-2]="!isMobile"  >
                                <label>Campaign Name</label>
                                <mat-form-field class="searchbox-form-field" appearance="fill">
                                    <mat-icon class="ims-grey" matPrefix>search</mat-icon>
                                    <input matInput type="text" #bookingNameSearchBox
                                        class="placeholder-text"
                                        aria-label="Booking Name" 
                                        name="bookingName"
                                        formControlName="bookingName"
                                        [matAutocomplete]="bookingNameAuto"
                                    />
                                    <mat-autocomplete #bookingNameAuto="matAutocomplete" [displayWith]="displayBookingName">
                                        <mat-option class="searchbox-tag-container dropdown-item" *ngFor="let item of bookingNameOptions" [value]="item">
                                            <div class="searchbox-tag-item">
                                                {{ item.bookingName }}
                                            </div>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="mt-2 grid">
                    <div class="grid-body">
                        <h4 class="font-bold">Search by Campaign Details</h4>
                        <div class="form-field-container">
                            <label>Campaign Date</label>
                            <mat-grid-list cols="1" [rowHeight]="form.get('bookingStartDate')?.hasError('invalidDateRange') ? !isMobile ? '90px': '110px' : '70px'">
                                <mat-grid-tile [rowspan]="isMobile ? 3: 1" [class.bookingDate-block]="isMobile">
                                    <label class="date-picker-grid-element grid-tile-label">From</label>
                                    <mat-form-field color="primary" class="date-picker-grid-element date-form-field">
                                        <input matInput [matDatepicker]="bookingStartDatePicker"
                                            formControlName="bookingStartDate" (focus)="bookingStartDatePicker.open()"
                                            [errorStateMatcher]="matcher" [readonly]="true">
        
                                        <mat-datepicker #bookingStartDatePicker></mat-datepicker>
                                    </mat-form-field>
                                    <label class="date-picker-grid-element grid-tile-label">To</label>
                                    <mat-form-field color="primary" class="date-picker-grid-element date-form-field">
                                        <input matInput 
                                            [matDatepicker]="bookingEndDatePicker"
                                            formControlName="bookingEndDate" 
                                            (focus)="bookingEndDatePicker.open()" 
                                            (dateChange)="onEndDateChange($event)"
                                            [errorStateMatcher]="matcher" 
                                            [readonly]="true">
        
                                        <mat-datepicker #bookingEndDatePicker></mat-datepicker>
        
                                        <mat-error #errorLabel *ngIf="form.get('bookingEndDate')?.hasError('required')">
                                            Booking End Date is invalid
                                        </mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>

                        <div class="form-field-container">
                            <label>Advertiser</label>
                            <mat-form-field class="drop-down-select-field drop-down-select-field-width-l" appearance="fill" color="primary">
                                <mat-icon class="ims-grey" matPrefix>search</mat-icon>
                                <mat-select class="drop-down-select-field-text" formControlName="advertiserName" name="advertiserName" placeholder="-" multiple>
                                    <mat-select-trigger>
                                        <span class="drop_down_selected_value">{{ form.get('advertiserName')?.value }}</span>
                                        <mat-icon class="drop_down_field_arrow">keyboard_arrow_down</mat-icon>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let item of advertiserNameListDto" [value]="item.advertiserName">
                                    {{ item.advertiserName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
        
                        <div [class.drop-down-select-field-row] ="!isMobile">
                            <div class="form-field-container">
                                <label>Format</label>
                                <mat-form-field class="drop-down-select-field w-50 drop-down-select-field-width-s" color="primary">
                                    <mat-select class="drop-down-select-field-text" formControlName="format" name="format" placeholder="-" multiple (selectionChange)="placementMapOptsChange('format')" [compareWith]="compareReferences">
                                        <mat-select-trigger>
                                            <span class="drop_down_selected_value">{{ showFormValueRefToItsRefName('format') }}</span>
                                            <mat-icon class="drop_down_field_arrow">keyboard_arrow_down</mat-icon>
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let format of adSizeList" [value]="format">
                                        <div>
                                            {{ format.refName }}
                                        </div>
                                        </mat-option>
                                    </mat-select>
                                    <mat-error #errorLabel *ngIf="form.get('format')?.hasError('required')">
                                        Format is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
            
                            <div class="form-field-container">
                                <label>Device</label>
                                <mat-form-field class="drop-down-select-field w-50 drop-down-select-field-width-s" color="primary" >
                                    <mat-select class="drop-down-select-field-text" formControlName="device" name="device" placeholder="-" multiple (selectionChange)="placementMapOptsChange('device');" [compareWith]="compareReferences">
                                        <mat-select-trigger>
                                            <span class="drop_down_selected_value">{{ showFormValueRefToItsRefName('device') }}</span>
                                            <mat-icon class="drop_down_field_arrow">keyboard_arrow_down</mat-icon>
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let device of deviceTypes" [value]="device" [disabled]="placementMapOpts('device', device)" >
                                        {{ device.refName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error #errorLabel *ngIf="form.get('device')?.hasError('required')">
                                        Device is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
            
                            <div class="form-field-container">
                                <label>Channel</label>
                                <mat-form-field class="drop-down-select-field w-50 drop-down-select-field-width-s" color="primary">
                                    <mat-select class="drop-down-select-field-text" formControlName="channel" name="channel" placeholder="-" multiple (selectionChange)="placementMapOptsChange('channel');" [compareWith]="compareReferences">
                                        <mat-select-trigger>
                                            <span class="drop_down_selected_value" >{{ showFormValueRefToItsRefName('channel') }}</span>
                                            <mat-icon class="drop_down_field_arrow">keyboard_arrow_down</mat-icon>
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let channel of channels" [value]="channel" [disabled]="placementMapOpts('channel', channel)">
                                        <div *ngIf="channel.refName !== 'UL'; else isUL">
                                            {{ channel.refName }}
                                        </div>
                                        <ng-template #isUL>
                                            UL 
                                        </ng-template>
                                        </mat-option>
                                    </mat-select>
                                    <mat-error #errorLabel *ngIf="form.get('channel')?.hasError('required')">
                                        Channel is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        
                        <div [class.drop-down-select-field-row] ="!isMobile">
                            <div class="form-field-container" [class.w-45percent]="!isMobile">
                                <label>Booking Status</label>
                                <mat-form-field class="drop-down-select-field drop-down-select-field-width-l" appearance="fill" color="primary">
                                    <mat-select class="drop-down-select-field-text" formControlName="bookingStatus" name="bookingStatus" placeholder="-" multiple>
                                        <mat-select-trigger>
                                            <span class="drop_down_selected_value">{{ form.get('bookingStatus')!.value }}</span>
                                            <mat-icon class="drop_down_field_arrow">keyboard_arrow_down</mat-icon>
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let status of bookingStatusEnum| keyvalue" [value]="status.value">
                                        {{ status.value }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error #errorLabel *ngIf="form.get('bookingStatus')?.hasError('required')">
                                        Either Campaign Name (Project Code) / Booking Status is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="btn-row">
                    <button class="button-long-1 search-btn" mat-raised-button color="primary" type="button" disableRipple [disabled]="showHideControl.disableSearchBtn"
                    (click)="exe_search();">Search</button>
                    <button class="button-long-1 export-btn" mat-raised-button color="primary" type="button" disableRipple [disabled]="showHideControl.disableExportBtn"
                    (click)="exe_export();">Export</button>
                </div>
            </div>
        </div>
    </form>
</div>

<ng-container ngif="isSearched">
    <div class="card mat-elevation-z8 mt-3 listing-container" *ngIf="showHideControl.showDetailTable; else errorOrNoResult">
        <table class="listing-table" mat-table [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="projectCode">
                <th class="table-header-project-code" mat-header-cell *matHeaderCellDef>TM Code</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'TM Code'"> 
                    <div class="cell-wrapper">
                      {{element.booking.projectCode ? element.booking.projectCode: "N/A"}} 
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="advertiserName">
                <th class="table-header-advertiser-name" mat-header-cell *matHeaderCellDef>Advertiser</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Advertiser'"> 
                    <div class="cell-wrapper">
                      {{element.booking.advertiserName}} 
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="bookingName">
                <th class="table-header-booking-name" mat-header-cell *matHeaderCellDef>Campaign Name</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Campaign Name'"> 
                    <div class="cell-wrapper">
                      {{element.booking.bookingName}} 
                    </div>
                </td>
            </ng-container>
    
            
            
            <ng-container matColumnDef="startDate">
                <th class="table-header-start-date" mat-header-cell *matHeaderCellDef>Start Date</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Start Date'"> 
                    <div class="cell-wrapper">
                        {{element.startDate}}
                    </div>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="endDate">
                <th class="table-header-end-date" mat-header-cell *matHeaderCellDef>End Date</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'End Date'"> 
                    <div class="cell-wrapper">
                        {{element.endDate}}
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="duration">
                <th class="table-header-duration" mat-header-cell *matHeaderCellDef>Duration (Days)</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Duration'"> 
                    <div class="cell-wrapper">
                      {{element.duration}} 
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="format">
                <th class="table-header-format" mat-header-cell *matHeaderCellDef>Format</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Format'"> 
                    <div class="cell-wrapper">
                      {{element.format.refName}} 
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="device">
                <th class="table-header-device" mat-header-cell *matHeaderCellDef>Device</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Device'"> 
                    <div class="cell-wrapper">
                      {{element.device.refName}} 
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="channel">
                <th class="table-header-channel" mat-header-cell *matHeaderCellDef>Channel</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Channel'"> 
                    <div class="cell-wrapper" *ngIf="element.channel.refName !== 'UL'; else isUL" >
                      {{element.channel.refName}} 
                    </div>
                    <ng-template #isUL>
                        <div class="cell-wrapper" >
                            UL 
                          </div>
                    </ng-template>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="requestedInventory">
                <th class="table-header-requested-inventory" mat-header-cell *matHeaderCellDef>Requested Inventory</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Requested Inventory'"> 
                    <div class="cell-wrapper">
                      {{element.requestedInventory | number: '1.0-0'}} 
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="createdBy">
                <th class="table-header-created-by" mat-header-cell *matHeaderCellDef>Created by</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Created by'"> 
                    <div class="cell-wrapper">
                        {{element.booking.creatorName}}
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="bookingStatus">
                <th class="table-header-booking-status" mat-header-cell *matHeaderCellDef>Booking Status</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Booking Status'"> 
                    <div class="cell-wrapper">
                        {{getbookingStatusEnum(element.booking.status)}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="phase">
                <th class="table-header-phase" mat-header-cell *matHeaderCellDef>Phase</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Phase'"> 
                    <div class="cell-wrapper">
                      {{element.phase}} 
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="actions">
                <th class="table-header-actions" mat-header-cell *matHeaderCellDef></th>
                <td class="listing-action-btn" mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element">
                        <ng-container *ngIf="!isInnerWidth1150();else actionMenu">
                            <button class="ims-grey-blue" mat-button (click)="getDailyDetail(element)">View Detail</button>
                            <ng-container *ngIf="disableEditBtnCheck(element); else enabledEdit">
                                <button class="ims-pale-green" mat-button disabled>Edit</button>
                            </ng-container>
                        </ng-container>
                        <ng-template #actionMenu>
                            <button color="primary" mat-icon-button [matMenuTriggerFor]="menu" aria-label="routing page menu">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button class="ims-grey-blue" mat-button (click)="getDailyDetail(element)">View Detail</button>
                                <ng-container *ngIf="disableEditBtnCheck(element); else enabledEdit">
                                    <button class="ims-pale-green" mat-button disabled>Edit</button>
                                </ng-container>
                            </mat-menu>
                        </ng-template>
                        <ng-template #enabledEdit>
                            <button class="ims-pale-green" mat-button (click)="editBooking(element.booking.id)">Edit</button>
                        </ng-template>
                    </ng-container>
                </td>
            </ng-container>
    
            
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="inventory-row" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    
        <mat-paginator class="custom-pagination" [pageSizeOptions]="[5, 10, 20]"
                        showFirstLastButtons
                        (page)="onPageChange($event)"
                        aria-label="Select page of bookings">
        </mat-paginator>
    </div>
    <ng-template #errorOrNoResult>
        <mat-error class="text-center" *ngIf="showHideControl.isError;else noResult">
            Please try again
        </mat-error>
        <ng-template #noResult>
            <div class="text-center" *ngIf="showHideControl.noResult;">
                No Result
            </div>
        </ng-template>
    </ng-template>
</ng-container>
