<mat-toolbar color="primary" class="navbar">
    <mat-toolbar-row>
        <div class="mobile-menu">
            <!-- <button mat-icon-button [matMenuTriggerFor]="menu"> -->
                <mat-icon [matMenuTriggerFor]="menu" class="menu-button">menu</mat-icon>
            <!-- </button> -->

            <mat-menu #menu="matMenu">
                <div mat-menu-item *ngFor="let item of navItems">
                    <a [routerLink]="item.path" routerLinkActive="active-mobile-link" class="mobile-menu-link" [routerLinkActiveOptions]="{ exact: true }">
                        <mat-icon>{{item.icon}}</mat-icon>
                        <span>{{item.label}}</span>
                    </a>
                </div>
            </mat-menu>
        </div>

        <a class="navbar-logo" routerLink="/">
            <img src="assets/hket-logo-white.png">
        </a>
        <a routerLink="/" class="navbar-title">{{title}}</a>
        <div class="spacer"></div>
        <div>
            <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="navbar-links">
                <a class="navbar-link" *ngFor="let item of navItems" [routerLink]="item.path"
                    routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">{{item.label}}</a>
            </nav>
            <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
        </div>

        <div class="navbar-user">
            <button mat-icon-button [matMenuTriggerFor]="userMenu" class="user-icon">
                <mat-icon>person</mat-icon>
            </button>
            <mat-menu #userMenu="matMenu" class="user-menu-card" xPosition="before">
                <div (click)="$event.stopPropagation()" class="user-menu-content">
                    <div class="user-info">
                        <div class="user-field">
                            <strong>Username:</strong> <span>{{ name }}</span>
                        </div>
                        <div class="user-field">
                            <strong>Role:</strong> <span>{{ role }}</span>
                        </div>
                        <div class="user-field">
                            <strong>Email:</strong> <span>{{ email }}</span>
                        </div>
                        <div class="user-field">
                            <strong>Team:</strong> <span>{{ team }}</span>
                        </div>
                        <div class="user-field">
                            <strong>Contact:</strong> <span>{{ userExtension?.extension }}</span> <mat-icon class="edit-icon small-icon" (click)="openExtensionDialog()">edit</mat-icon>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <button mat-button (click)="logout()" class="logout-button">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>
        </div>
    </mat-toolbar-row>
</mat-toolbar>