import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../_service/user.service';
import { UserExtensionDto } from '@ims-shared/dto/user.dto';

@Component({
  selector: 'app-extension-dialog',
  templateUrl: './extension-dialog.component.html',
  styleUrls: ['./extension-dialog.component.css']
})
export class ExtensionDialogComponent implements OnInit {
  extensionForm: FormGroup;
  isSubmitting = false;
  
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private dialogRef: MatDialogRef<ExtensionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { extension: string | null }
  ) {
    this.extensionForm = this.fb.group({
      extension: [
        data.extension || '', 
        [
          Validators.required, 
          Validators.pattern(/^[0-9 ]*$/),
          this.extensionLengthValidator
        ]
      ]
    });
    
    this.dialogRef.updateSize('400px');
    this.dialogRef.addPanelClass('extension-dialog');
  }

  ngOnInit(): void {
    this.extensionForm.get('extension')?.valueChanges.subscribe(() => {
      this.extensionForm.get('extension')?.markAsTouched();
    });
  }

  extensionLengthValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    const valueWithoutSpaces = value.replace(/\s/g, '');
    
    // If it has spaces, max length is 9
    // If it has no spaces, max length is 8
    if ((value.includes(' ') && value.length > 9) || 
        (!value.includes(' ') && valueWithoutSpaces.length > 8)) {
      return { invalidLength: true };
    }
    return null;
  }

  onSubmit(): void {
    if (this.extensionForm.invalid || this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    const extension = this.extensionForm.get('extension')?.value;

    this.userService.upsertExtension(extension).subscribe({
      next: (response: UserExtensionDto) => {
        this.dialogRef.close(response);
      },
      error: (error) => {
        console.error('Error updating extension:', error);
        this.isSubmitting = false;
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
