<div class="card" [formGroup]="form">
    <div class="card-body">
        <div class="targeting-setting-header">
            <h3>Campaign Source</h3>
            <div class="ml-3percent">
                <label class="mt-10px">Campaign</label>
                <mat-form-field appearance="fill">
                    <mat-chip-grid #chipGrid>
                        <mat-chip-row *ngFor="let selected of campaignSourceArray.value; let i = index"
                            (removed)="resetCampaignSource(i)">
                            {{((selected?.projectCode || 'N/A') + ' \\ ' + (selected?.advertiserName || '')+ ' \\ ' + (selected?.bookingName || ''))}}
                            <button matChipRemove aria-label="'remove ' + searchCriteria">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input #campaignSourceInput matInput type="text" [matChipInputFor]="chipGrid" formControlName="campaignSourceInput"
                        [matAutocomplete]="autoCampaignSource" [readonly]="campaignSourceArray.value.length > 0 && !form.get('campaignSourceInput')?.value">
                    <mat-autocomplete #autoCampaignSource="matAutocomplete"
                        [displayWith]="displayCampaignSource" (optionSelected)="getTargetingSettingDetails($event.option.value);campaignSourceInput.value = ''">
                        <mat-option *ngFor="let option of campaignSourceOptions" [value]="option">
                            {{(option.projectCode || 'N/A') + ' \\ ' + option.advertiserName+ ' \\ ' + option.bookingName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div class="targeting-setting-header">
            <div class="d-flex">
                <h3>Targeting Setting Preview</h3>
                <mat-spinner class="spinner-size" [class.d-none]="isloading"></mat-spinner>
            </div>
        </div>
        <ng-container>
            <div class="form-field-container ml-3percent">
                <section>
                    <label>Audience Targeting</label>
                    <mat-checkbox color="primary" formControlName="copyAudienceTargetings"></mat-checkbox>
                </section>
                
                <mat-form-field appearance="fill">
                    <input matInput type="text" [value]="displayAudienceTargeting(audienceTargetings.value)" readonly>
                </mat-form-field>
                <mat-error *ngIf="expiredAudienceTargetingSegments && expiredAudienceTargetingSegments.length > 0">
                    {{displayAudienceTargeting(expiredAudienceTargetingSegments)}} {{expiredAudienceTargetingSegments.length === 1 ? "is" : "are"}} expired
                </mat-error>
            </div>
        
            <div class="form-field-container ml-3percent">
                <section>
                    <label>Content Targeting</label>
                    <mat-checkbox color="primary" formControlName="copyContentTags"></mat-checkbox>
                </section>
                <mat-form-field appearance="fill">
                    <input matInput type="text" [value]="displayContentTags()" readonly>
                </mat-form-field>
            </div>
        
            <div class="form-field-container ml-3percent">
                <section>
                    <label>Keywords</label>
                    <mat-checkbox color="primary" formControlName="copyKeywords"></mat-checkbox>
                </section>
                <mat-form-field appearance="fill">
                    <input matInput type="text" [value]="displayKeywords()" readonly>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
    <div class="dialog-action-container">
        <mat-dialog-actions>
            <button mat-stroked-button mat-dialog-close>Cancel</button>
            <button mat-flat-button color="primary" (click)="copy()" [disabled]="!canCopy">Copy</button>
        </mat-dialog-actions>
    </div>
</div>