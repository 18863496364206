<h2 mat-dialog-title>{{isNewObjective ? 'Create' : 'Edit'}} Objective</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="outline" class="full-width">
      <textarea matInput formControlName="description" rows="3" placeholder="Enter objective description"></textarea>
      <mat-error *ngIf="form.get('description')?.hasError('required')">
        Description is required
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="onSave()">
    {{isNewObjective ? 'Create' : 'Confirm'}}
  </button>
</mat-dialog-actions> 