import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { BookingDetailsDto } from "@ims-shared/dto/booking.dto";
import { BookingDetailsRequestInventoryValidationResult } from "@ims-shared/dto/inventory.dto";
import { ReferenceDto } from "@ims-shared/dto/reference.dto";
import { BookingStatus } from "@ims-shared/enum/booking-status";
import { BookingValidationStatus } from '@ims-shared/enum/booking-validation-status';
import { UserRole } from "@ims-shared/enum/user-role";
import { InventoryService } from "src/app/_service/inventory.service";
import { isBookingFieldDisabled } from '@ims-shared/utils/permission.util';
import { BookingField } from '@ims-shared/enum/booking-field';
import { User } from "src/app/auth/auth.service";
import { PlacementMap } from "@ims-shared/dto/placements.dto";
import { bookingDateValidation, bookingDetailRecordToDto, CustomErrorStateMatcher, getBookingDetailValidationWarningMessage } from "../booking.util";

export interface BookingDetailRecord {
    bookingDetailId: number;
    phase: number;
    channel: ReferenceDto;
    device: ReferenceDto;
    format: ReferenceDto;
    tmRequestInventory: number;
    validationStatus?: BookingValidationStatus;
    message?: string;
    shortMessage?: string;
    isEditing?: boolean;
}

@Component({
    selector: 'app-create-detail-dialog',
    templateUrl: './create-detail-dialog.component.html',
    styleUrls: ['../booking.component.css', './create-detail-dialog.component.css']
})
export class CreateBookingDetailDialog implements OnInit {
    form: FormGroup;
    dataSource: MatTableDataSource<any>[] = [];
    summaryDataSource: MatTableDataSource<any>;
    summaryColumns: string[] = ['format', 'totalInventory'];
    bookingChannelOptions: ReferenceDto[];
    bookingDeviceTypeOptions: ReferenceDto[];
    bookingFormatOptions: ReferenceDto[];
    bookingDetailColumns: string[];
    isTm: boolean;
    status: BookingStatus;
    user: User;
    bookingId: number;
    placementMapping: PlacementMap;
    validated: boolean = false;
    today!: Date;
    matcher = new CustomErrorStateMatcher();
    singlePhase: boolean;
    singlePhaseStartDate: string;
    singlePhaseEndDate: string;
    singlePhaseExcludeWeekend: boolean;
    singlePhaseExcludePublicHoliday: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private inventoryService: InventoryService,
        public dialogRef: MatDialogRef<CreateBookingDetailDialog>,
        private fb: FormBuilder
    ) {
        const now = new Date();
        this.singlePhase = data.singlePhase;
        this.singlePhaseStartDate = data.startDate;
        this.singlePhaseEndDate = data.endDate;
        this.singlePhaseExcludeWeekend = data.excludeWeekend;
        this.singlePhaseExcludePublicHoliday = data.excludePublicHoliday;
        this.status = data.status;
        this.user = data.user;
        this.bookingDeviceTypeOptions = data.bookingDeviceTypeOptions;
        this.bookingFormatOptions = data.bookingFormatOptions;
        this.bookingDetailColumns = data.bookingDetailColumns;
        this.isTm = data.isTm;
        this.placementMapping = data.placementMapping;
        this.bookingId = data.bookingId;
        this.today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        if ([UserRole.SALES, UserRole.SALES_ADMIN].includes(this.user.role) && !this.user.crossDept) {
            this.bookingChannelOptions = data.bookingChannelOptions.filter((channel: ReferenceDto) =>
                channel.channelPlatform?.toLowerCase() == this.user.team.toLowerCase()
            );
        } else {
            this.bookingChannelOptions = data.bookingChannelOptions;
        }

        this.form = this.fb.group({
            phases: this.fb.array([]),
            totalInventory: this.fb.control(0)
        });

        this.initializeFormWithPhases(data);
        this.summaryDataSource = new MatTableDataSource<any>([]);
    }

    ngOnInit() {
        if (this.phases.length === 0) {
            this.addPhase();
        }
    }

    get phases() {
        return this.form.get('phases') as FormArray;
    }

    getPhase(index: number): FormGroup {
        return this.phases.at(index) as FormGroup;
    }

    getBookingDetails(phaseIndex: number): FormArray {
        return this.getPhase(phaseIndex).get('bookings') as FormArray;
    }

    getBookingsDataSource(phaseIndex: number): MatTableDataSource<any> {
        const phase = this.getPhase(phaseIndex);
        const bookings = phase.get('bookings') as FormArray;
        return new MatTableDataSource(bookings.controls);
    }

    initializeFormWithPhases(data: any): void {
        data.phases.forEach((phase: any) => {
            const phaseGroup = this.createPhaseGroup(phase);
            this.phases.push(phaseGroup);
        }, { validators: bookingDateValidation as ValidatorFn });
        this.updateAllDataSources();
    }

    createPhaseGroup(phase: any): FormGroup {
        const isSinglePhase = this.singlePhase;
        const startDate = isSinglePhase ? this.singlePhaseStartDate : phase.startDate;
        const endDate = isSinglePhase ? this.singlePhaseEndDate : phase.endDate;
        const excludeWeekend = isSinglePhase ? this.singlePhaseExcludeWeekend : false;
        const excludePublicHoliday = isSinglePhase ? this.singlePhaseExcludePublicHoliday : false;

        const isPhaseStarted = new Date(startDate) < this.today;

        const bookingDetails = phase.bookings || [];
        const bookingFormGroups = this.createBookingFormGroups(bookingDetails, isPhaseStarted);

        return this.fb.group({
            phase: [this.phases.length + 1],
            startDate: [{ value: startDate, disabled: this.isBookingApprovedAndStarted(startDate) }, Validators.required],
            endDate: [{ value: endDate, disabled: false }, Validators.required],
            excludeWeekend: [{ value: excludeWeekend, disabled: isPhaseStarted }],
            excludePublicHoliday: [{ value: excludePublicHoliday, disabled: isPhaseStarted }],
            bookings: bookingFormGroups,
            isPhaseStarted: [isPhaseStarted]
        }, { validators: bookingDateValidation as ValidatorFn });
    }

    private createBookingFormGroups(bookingDetails: BookingDetailRecord[], isPhaseStarted: boolean): FormArray {
        const bookingFormGroups = this.fb.array(
            bookingDetails.map(bookingDetail => this.createBookingDetailGroup(bookingDetail, isPhaseStarted))
        );

        if (bookingDetails.length === 0) {
            bookingFormGroups.push(this.createBookingDetailGroup(undefined, isPhaseStarted));
        }

        return bookingFormGroups;
    }

    createBookingDetailGroup(bookingDetail?: BookingDetailRecord, isPhaseStarted: boolean = false): FormGroup {
        const createControl = (value: any, fieldDisabled: boolean) =>
            this.fb.control({ 
                value: value ?? null, 
                disabled: isPhaseStarted || fieldDisabled 
            }, Validators.required);

        const channelDisabled = bookingDetail ? isBookingFieldDisabled(this.status, this.user.role, BookingField.DETAIL_CHANNEL) : true;
        const deviceDisabled = bookingDetail ? isBookingFieldDisabled(this.status, this.user.role, BookingField.DETAIL_DEVICE) : true;
        const formatDisabled = bookingDetail ? isBookingFieldDisabled(this.status, this.user.role, BookingField.DETAIL_FORMAT) : false;
        const tmRequestInventoryDisabled = this.status && bookingDetail ? isBookingFieldDisabled(this.status, this.user.role, BookingField.DETAIL_REQUEST_INVENTORY) : false;

        return this.fb.group({
            bookingDetailId: [bookingDetail?.bookingDetailId ?? null],
            channel: createControl(bookingDetail?.channel, channelDisabled),
            device: createControl(bookingDetail?.device, deviceDisabled),
            format: createControl(bookingDetail?.format, formatDisabled),
            tmRequestInventory: this.fb.control({ 
                value: this.formatInventory(bookingDetail?.tmRequestInventory ?? null), 
                disabled: tmRequestInventoryDisabled 
            }, [Validators.required]),
            newDetail: [!bookingDetail?.bookingDetailId],
            validationStatus: [bookingDetail?.validationStatus ?? null],
            message: [bookingDetail?.message ?? null],
            shortMessage: [bookingDetail?.shortMessage ?? null],
            isEditing: [!bookingDetail],
            channelOptions: [this.bookingChannelOptions],
            deviceOptions: [this.bookingDeviceTypeOptions],
            formatOptions: [this.bookingFormatOptions],
            canSubmit: [false],
            remainInventory: [null]
        });
    }

    updateAllDataSources() {
        this.dataSource = this.phases.controls.map(phase =>
            new MatTableDataSource((phase.get('bookings') as FormArray).controls)
        );
    }

    addPhase() {
        const currentPhases = this.phases.controls;
        const nextPhaseNumber = currentPhases.length + 1;
        
        const newPhase = this.createPhaseGroup([]);
        newPhase.patchValue({ phase: nextPhaseNumber });
        
        this.phases.push(newPhase);
        this.updateAllDataSources();
    }

    removePhase(index: number) {
        const phase = this.getPhase(index);
        
        if (!phase.get('isPhaseStarted')?.value) {
            this.phases.removeAt(index);
            
            // Reorder remaining phases
            this.phases.controls.forEach((phaseControl, i) => {
                phaseControl.patchValue({ phase: i + 1 });
            });
            
            this.updateAllDataSources();
        }
    }

    addBookingDetail(phaseIndex: number) {
        const phase = this.getPhase(phaseIndex);
        const started = phase.get('isPhaseStarted')?.value;

        if (!started) {
            const bookings = this.getBookingDetails(phaseIndex);
            bookings.push(this.createBookingDetailGroup(undefined, started));
            this.updateAllDataSources();
        }
    }

    removeBookingDetail(phaseIndex: number, bookingIndex: number) {
        const phase = this.getPhase(phaseIndex);
        if (!phase.get('isPhaseStarted')?.value) {
            const bookings = this.getBookingDetails(phaseIndex);
            bookings.removeAt(bookingIndex);
            this.checkBookingDetailDuplicated(phaseIndex);
            this.updateAllDataSources();
        }
    }

    isConfirmButtonDisabled(): boolean {
        return this.phases.invalid || this.phases.controls.some(phase => {
            const bookings = phase.get('bookings') as FormArray;
            return bookings.controls.some(booking => {
                const inventoryValue = booking.get('tmRequestInventory')?.value;
                const isInventoryEmpty = !inventoryValue || inventoryValue === '';
                const canSubmit = booking.get('canSubmit')?.value;
                const isValidationStatusEmpty = !booking.get('validationStatus')?.value;
                return isInventoryEmpty || !canSubmit || isValidationStatusEmpty;
            });
        });
    }

    validateBookingDetails() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const bookingDetails: BookingDetailsDto[] = [];
        this.phases.controls.forEach((phase, phaseIndex) => {
            const bookings = this.getBookingDetails(phaseIndex);
            const phaseBookingDetails = bookingDetailRecordToDto(phase.getRawValue());
            phaseBookingDetails.forEach(detail => {
                if (detail.tmRequestInventory) {
                    // Remove commas and parse to integer
                    detail.tmRequestInventory = Number(this.removeCommaFromInventory(detail.tmRequestInventory));
                }
            });
            bookingDetails.push(...phaseBookingDetails);

            bookings.controls.forEach(control => {
                control.get('validationStatus')?.setValue(BookingValidationStatus.PENDING);
            });
        });

        this.updateAllDataSources();
        this.inventoryService.validateBookingDetailsRequestInventory(this.bookingId, bookingDetails).subscribe(
            (results: BookingDetailsRequestInventoryValidationResult[]) => {
                if (results.length > 0) {
                    this.validated = true;
                    let resultIndex = 0;
                    this.phases.controls.forEach((phase, phaseIndex) => {
                        const bookingDetails = this.getBookingDetails(phaseIndex);
                        bookingDetails.controls.forEach((control, index) => {
                            const result = results[resultIndex++];
                            const { canSubmit, warningMessage, shortWarningMessage } = getBookingDetailValidationWarningMessage(
                                this.user.role,
                                this.isTm,
                                result.sufficientDailyInventory,
                                result.sufficientTotalInventory,
                                result.remainInventory
                            );

                            const status = result.sufficientDailyInventory && result.sufficientTotalInventory ? BookingValidationStatus.VALID
                                : result.sufficientTotalInventory && !result.sufficientDailyInventory ? BookingValidationStatus.WARNING
                                    : BookingValidationStatus.INVALID;

                            control.get('message')?.setValue(warningMessage);
                            control.get('shortMessage')?.setValue(shortWarningMessage);
                            control.get('canSubmit')?.setValue(canSubmit);
                            control.get('validationStatus')?.setValue(status);
                            control.get('remainInventory')?.setValue(result.remainInventory);

                        });
                    });

                    this.updateAllDataSources();
                    this.updateSummaryDataSource();
                }
            }
        );
    }

    updateSummaryDataSource() {
        const summary = this.phases.controls.reduce((acc: any, phase, phaseIndex: number) => {
            const bookings = this.getBookingDetails(phaseIndex);
            bookings.controls.forEach(control => {
                const format = control.get('format')?.value;
                const tmRequestInventoryStr = control.get('tmRequestInventory')?.value || '0';
                const tmRequestInventory = parseInt(tmRequestInventoryStr.toString().replace(/,/g, ''), 10) || 0;
                
                if (format) {
                    if (!acc[format.refName]) {
                        acc[format.refName] = { format: format.refName, totalInventory: 0 };
                    }
                    acc[format.refName].totalInventory += tmRequestInventory;
                }
            });
            return acc;
        }, {});

        const summaryData = Object.values(summary)
            .filter((item: any) => item.format !== 'Total')
            .map((item: any) => ({
                ...item,
                totalInventory: this.formatInventory(item.totalInventory)
            }));

        const total = Object.values(summary).reduce((acc: number, item: any) => {
            const itemTotal = typeof item.totalInventory === 'string' 
                ? parseInt(item.totalInventory.replace(/,/g, ''), 10) 
                : item.totalInventory || 0;
            return acc + itemTotal;
        }, 0);

        // Update the form's totalInventory control with the calculated total
        this.form.get('totalInventory')?.setValue(total);

        this.summaryDataSource.data = [
            ...summaryData,
            { format: 'Total', totalInventory: this.formatInventory(total) }
        ];
    }

    inventoryOnChange(phaseIndex: number, bookingIndex: number, value: string) {
        const control = this.getBookingDetails(phaseIndex).at(bookingIndex);
        const inventoryControl = control.get('tmRequestInventory');
        
        if (!value || value === '') {
            inventoryControl?.setErrors({ required: true });
            this.updateAllDataSources();
            return;
        }
        
        // Check for any special characters or letters except commas
        if (/[^0-9,]/.test(value)) {
            inventoryControl?.setErrors({ invalid: true });
            this.updateAllDataSources();
            return;
        }
        
        const numValue = parseInt(value.replace(/,/g, ''));
        if (isNaN(numValue) || numValue <= 0 || numValue % 1 !== 0) {
            inventoryControl?.setErrors({ invalid: true });
        } else {
            inventoryControl?.setErrors(null);
        }
        this.updateAllDataSources();
    }

    onFieldBlur(phaseIndex: number, bookingIndex: number) {
        const control = this.getBookingDetails(phaseIndex).at(bookingIndex);
        const inventory = control.get('tmRequestInventory');
        if (inventory && inventory.dirty) {
            this.resetValidationStatus(phaseIndex, bookingIndex);
        }
    }

    bookingDetailOnChange(phaseIndex: number, bookingIndex: number) {
        const bookingFormGroup = this.getBookingDetails(phaseIndex).at(bookingIndex);
        const selectedFormat = bookingFormGroup.get('format')?.value;

        if (selectedFormat) {
            const availableDevices = new Set(this.placementMapping[selectedFormat.refName].map(placement => placement.device.refName));
            const availableChannels = new Set(this.placementMapping[selectedFormat.refName].map(placement => placement.channel.refName));

            bookingFormGroup.get('deviceOptions')?.setValue(this.bookingDeviceTypeOptions.filter(device => availableDevices.has(device.refName)));
            bookingFormGroup.get('channelOptions')?.setValue(this.bookingChannelOptions.filter(channel => availableChannels.has(channel.refName)));
            bookingFormGroup.get('device')?.enable();

            const selectedDevice = bookingFormGroup.get('device')?.value;
            if (selectedDevice && selectedFormat) {
                const availableChannels = new Set(
                    this.placementMapping[selectedFormat.refName]
                        .filter(placement => placement.device.refName === selectedDevice.refName)
                        .map(placement => placement.channel.refName)
                );
                bookingFormGroup.get('channelOptions')?.setValue(this.bookingChannelOptions.filter(channel => availableChannels.has(channel.refName)));
                bookingFormGroup.get('channel')?.enable();

                const selectedChannel = bookingFormGroup.get('channel')?.value;
                if (selectedChannel) {
                    this.checkBookingDetailDuplicated(phaseIndex);
                }
            }
        }

        bookingFormGroup.get('validationStatus')?.setValue(null);
        this.updateAllDataSources();
    }

    checkBookingDetailDuplicated(phaseIndex: number) {
        this.getBookingDetails(phaseIndex).controls.forEach((control, index) => {
            const isDuplicated = this.isBookingDetailDuplicated(phaseIndex, control, index);
            if (isDuplicated) {
                control.get('format')?.setErrors({ duplicated: true });
            } else {
                control.get('format')?.setErrors(null);
            }
        });
    }

    isBookingDetailDuplicated(phaseIndex: number, control: AbstractControl<any, any>, controlIndex: number): boolean {
        const selectedFormat = control.get('format')?.value;
        const selectedDevice = control.get('device')?.value;
        const selectedChannel = control.get('channel')?.value;

        return this.getBookingDetails(phaseIndex).controls.some((control, index) => {
            return control.get('format')?.value?.refName === selectedFormat?.refName
                && control.get('device')?.value?.refName === selectedDevice?.refName
                && control.get('channel')?.value?.refName === selectedChannel?.refName
                && index !== controlIndex;
        });
    }

    resetValidationStatus(phaseIndex: number, bookingIndex: number) {
        this.getBookingDetails(phaseIndex).at(bookingIndex).get('validationStatus')?.setValue(null);
        this.updateAllDataSources();
    }

    submit() {
        this.form.enable();
        
        // Reorder phases sequentially before submitting
        const phases = this.form.value.phases;
        
        // Sort phases by their current phase number to maintain relative order
        phases.sort((a: any, b: any) => a.phase - b.phase);
        
        // Reassign phase numbers sequentially starting from 1
        phases.forEach((phase: any, index: number) => {
            const phaseNumber = index + 1;
            phase.phase = phaseNumber;
            phase.bookings.forEach((booking: any) => {
                if (booking.tmRequestInventory) {
                    booking.tmRequestInventory = Number(booking.tmRequestInventory.toString().replace(/,/g, ''));
                }
            });
        });

        this.dialogRef.close(this.form.value);
    }

    validateDisabled(): boolean {
        if (!this.status) {
            return false;
        }
        return isBookingFieldDisabled(this.status, this.user.role, BookingField.DETAIL_REQUEST_INVENTORY);
    }

    addButtonDisabled(phaseIndex?: number): boolean {
        if (!this.status) {
            return false;
        }

        if (phaseIndex !== undefined) {
            const isPhaseStarted = this.getPhase(phaseIndex).get('isPhaseStarted')?.value;
            if (isPhaseStarted) {
                return true;
            }
        }
        
        return isBookingFieldDisabled(this.status, this.user.role, BookingField.ADD_DETAIL);
    }

    deleteButtonDisabled(phaseIndex: number, bookingIndex: number): boolean {
        const phase = this.getPhase(phaseIndex);
        if (phase.get('isPhaseStarted')?.value) {
            return true;
        }
        const disabled = isBookingFieldDisabled(this.status, this.user.role, BookingField.DELETE_DETAIL);
        const isNewDetail = this.getBookingDetails(phaseIndex).at(bookingIndex).get('newDetail')?.value;
        return isNewDetail ? false : disabled;
    }

    formatSelectDisabled(phaseIndex: number, bookingIndex: number) {
        const bookingFormGroup = this.getBookingDetails(phaseIndex).at(bookingIndex);
        const device = bookingFormGroup.get('device')?.value;
        return !device;
    }

    channelSelectDisabled(phaseIndex: number, bookingIndex: number) {
        const bookingFormGroup = this.getBookingDetails(phaseIndex).at(bookingIndex);
        const format = bookingFormGroup.get('format')?.value;
        return !format;
    }

    isBookingApprovedAndStarted(startDateStr: string | null): boolean {
        const startDate = new Date(startDateStr ?? '');
        const today = new Date();
        startDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return this.status === BookingStatus.APPROVED && startDate <= today;
    }

    getBookingRequestInventory(booking: FormGroup) {
        const value = booking.get('tmRequestInventory')?.value
        if (value === null || value === undefined) return value
        return value.toString().replace(/[^0-9]/g, '');
    }

    getSummaryTotalInventory(index: number) {
        const row = this.summaryDataSource.data[index];
        const inventory = this.removeCommaFromInventory(row.totalInventory);
        const numberInventory = parseInt(inventory);
        return numberInventory;
    }

    private removeCommaFromInventory(value: string | number): string {
        return value.toString().replace(/,/g, '');
    }

    private formatInventory(value: string | number | null): string {
        if (value === null || value === '') return '';
        const num = typeof value === 'string' ? parseInt(value.replace(/,/g, ''), 10) : value;
        if (isNaN(num)) return '';
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    isDeletePhaseDisabled(phaseIndex: number): boolean {
        const phase = this.getPhase(phaseIndex);
        return phase.get('isPhaseStarted')?.value;
    }
}