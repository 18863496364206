import { BookingDetailComponent } from './booking-detail/booking-detail.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { authGuard } from './auth/auth.guard';
import { AuthComponent } from './auth/auth.component';
import { ErrorComponent } from './error/error.component';
import { BookingListComponent } from './booking-list/booking-list.component';
import { BookingComponent } from './booking/booking.component';
import { InventoryComponent } from './inventory/inventory.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { InventoryControlComponent } from './inventory-control/inventory-control.component';
import { UserRole } from '@ims-shared/enum/user-role';

const ALL_USER_ROLES_LIST = Object.values(UserRole);

const routes: Routes = [
  { path: "", redirectTo: "/booking/list", pathMatch: "full" },
  { path: "error", component: ErrorComponent },
  { path: "login", component: LoginComponent },
  { path: "auth", component: AuthComponent, canActivate: [authGuard], data: { allowedRole: ALL_USER_ROLES_LIST }},
  { path: "inventory", children: [
    { path: "", component: InventoryComponent, canActivate: [authGuard], data: { allowedRole: ALL_USER_ROLES_LIST } },
    { path: "control", component: InventoryControlComponent, canActivate: [authGuard], data: { allowedRole: [UserRole.ADMIN, UserRole.MS, UserRole.TM, UserRole.SUPER_USER] }}
  ]},
  { path: "config", component: ConfigurationComponent, canActivate: [authGuard] , data: { allowedRole: [UserRole.ADMIN, UserRole.MS, UserRole.SUPER_USER, UserRole.SALES_ADMIN] }},
  { path: "booking", children: [
    { path: "list", component: BookingListComponent, canActivate: [authGuard], data: { allowedRole: ALL_USER_ROLES_LIST } },
    { path: "create", component: BookingComponent, canActivate: [authGuard], data: { allowedRole: ALL_USER_ROLES_LIST } },
    { path: "detail", component: BookingDetailComponent, canActivate: [authGuard], data: { allowedRole: [UserRole.ADMIN, UserRole.MS, UserRole.TM, UserRole.SALES_ADMIN, UserRole.SUPER_USER] }},
    { path: ":id", component: BookingComponent, canActivate: [authGuard], data: { allowedRole: ALL_USER_ROLES_LIST } },
  ]},
  { path: "**", redirectTo: "/error?status=404" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
