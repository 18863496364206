import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { InterestTagsDataSource, InterestTagsDropDownService } from "./interest-tags.service";
import { FlatTreeControl } from "@angular/cdk/tree";
import { ContentTagDto } from "@ims-shared/dto/content-tag.dto";
import { FormArray, FormBuilder, FormControl } from "@angular/forms";

@Component({
    selector: 'app-interest-tags',
    templateUrl: './interest-tags.component.html',
    styleUrls: ['./interest-tags.component.css'],
})
export class InterestTagsComponent implements OnInit, OnChanges {
    treeControl: FlatTreeControl<ContentTagDto>;
    dataSource: InterestTagsDataSource;
    filteredDataSource: InterestTagsDataSource;
    tagControl: FormControl;
    tagMap: Map<number, ContentTagDto> = new Map();

    @Input() form: any;

    @Input() readonly!: boolean;

    ngOnInit(): void {
        this.tagControl.valueChanges.subscribe(value => {
            this.filterTags();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["readonly"]) {
            if (this.tagControl && this.readonly) {
                this.tagControl.disable();
            }
        }
    }

    constructor(private dropDownService: InterestTagsDropDownService, private fb: FormBuilder) {
        this.tagMap = dropDownService.tagMap;
        this.tagControl = new FormControl("");
        this.treeControl = new FlatTreeControl<ContentTagDto>(this.getLevel, this.isExpandable);
        this.dataSource = new InterestTagsDataSource(this.treeControl, dropDownService);
        this.filteredDataSource = this.dataSource;
        this.initializeData();
    }

    get contentTagsArray() {
        return this.form.get('contentTags') as FormArray;
    }

    async initializeData() {
        this.dataSource.data = await this.dropDownService.initialData();
    }

    getLevel = (tag: ContentTagDto) => tag.level;

    isExpandable = (tag: ContentTagDto) => !tag.is_leaf;

    hasChild = (_: number, tag: ContentTagDto) => !tag.is_leaf;

    addContentTag(selectedTag: ContentTagDto) {
        const existingIndex = this.contentTagsArray.controls.findIndex(control => {
            const id = control.get('id')?.value;
            return id && id === selectedTag.id;
        });
        // tag already exists
        if (existingIndex > -1) {
            return;
        }
        this.contentTagsArray.push(this.fb.group({
            id: selectedTag.id,
            tag: selectedTag.tag,
            valueId: selectedTag.valueId,
        }));
    }

    removeContentTag(index: number) {
        this.contentTagsArray.removeAt(index);
    }

    // dynamic css for tree nodes
    getTagClass(tag: ContentTagDto) {
        return `tree-level-${tag.level}`;
    }

    private flattenTags(tags: ContentTagDto[]): ContentTagDto[] {
        const flattenedTags: ContentTagDto[] = [];
        const addedTagIds = new Set<number>();

        const flatten = (tag: ContentTagDto) => {
            if (!addedTagIds.has(tag.id)) {
                flattenedTags.push(tag);
                addedTagIds.add(tag.id);
            }
            if (tag.children) {
                tag.children.forEach(flatten);
            }
        };

        tags.forEach(flatten);
        return flattenedTags;
    }

    private getMatchingTags(tag: ContentTagDto, filterValue: string): boolean {
        const match = tag.tag.toLowerCase().includes(filterValue.toLowerCase());
        const hasChildrenMatch = tag.children && tag.children.some(child => this.getMatchingTags(child, filterValue));
        return match || hasChildrenMatch;
    }

    private filterTags() {
        const filterValue = this.tagControl.value;
        if (!filterValue) {
            this.filteredDataSource = new InterestTagsDataSource(this.treeControl, this.dropDownService);
            this.filteredDataSource.data = [...this.dataSource.data];
            this.treeControl.collapseAll();
            return;
        }

        const flattenTags = this.flattenTags(this.dataSource.data);
        const filteredTags = flattenTags.filter(tag => this.getMatchingTags(tag, filterValue));

        this.filteredDataSource = new InterestTagsDataSource(this.treeControl, this.dropDownService);
        this.filteredDataSource.data = filteredTags;
    }
}