import { BookingField } from "../enum/booking-field";
import { BookingStatus } from "../enum/booking-status"
import { UserRole } from "../enum/user-role"

type PermissionMap = {
    [key in BookingStatus]?: {
        [key in UserRole]?: BookingField[]
    }
}

const targetMarketingFields = [
    BookingField.CAMPAIGN_OBJECTIVE,
    BookingField.KEY_RESULT_IMPRESSION,
    BookingField.KEY_RESULT_CTR,
    BookingField.KEY_RESULT_OTHER,
    BookingField.ADDITIONAL_INFORMATION,
    BookingField.AUDIENCE_TARGETING,
    BookingField.CONTENT_TARGETING,
    BookingField.KEYWORDS
];

const commonFieldsBeforeApproval = [
    BookingField.BOOKING_NAME,
    BookingField.SAVE,
    BookingField.CANCEL_AND_COPY,
    BookingField.START_DATE,
    BookingField.END_DATE,
    BookingField.EXCLUDE_WEEKEND,
    BookingField.EXCLUDE_PUBLIC_HOLIDAY,
    BookingField.REMARKS,
    BookingField.DETAIL_REQUEST_INVENTORY,
    BookingField.ADD_DETAIL,
    BookingField.DELETE_DETAIL,
    BookingField.KEY_RESULT_IMPRESSION
]

// This permission is for allowing user to access the booking page fields
const permissions: PermissionMap = {
    [BookingStatus.PENDING_TM_RESPONSE]: {
        [UserRole.ADMIN]: [...commonFieldsBeforeApproval, BookingField.SUBMIT, BookingField.COPY_TM_FIELDS, ...targetMarketingFields], // temp add submit and target marketing fields for uat
        [UserRole.MS]: [...commonFieldsBeforeApproval],
        [UserRole.TM]: [...commonFieldsBeforeApproval, BookingField.SUBMIT, BookingField.COPY_TM_FIELDS, ...targetMarketingFields],
        [UserRole.SALES_ADMIN]: [...commonFieldsBeforeApproval],
        [UserRole.SALES]: [...commonFieldsBeforeApproval],
        [UserRole.SUPER_USER]: [...commonFieldsBeforeApproval, BookingField.SUBMIT, ...targetMarketingFields] // temp add submit and target marketing fields for uat
    },
    [BookingStatus.PENDING_TM_RESPONSE_CONFIRMATION]: {
        [UserRole.ADMIN]: [...commonFieldsBeforeApproval, BookingField.CONFIRM, BookingField.COPY_TM_FIELDS, ...targetMarketingFields],  // temp add confirm and target marketing fields for uat
        [UserRole.MS]: [...commonFieldsBeforeApproval],
        [UserRole.TM]: [...commonFieldsBeforeApproval, BookingField.COPY_TM_FIELDS, ...targetMarketingFields],
        [UserRole.SALES_ADMIN]: [...commonFieldsBeforeApproval, BookingField.CONFIRM],
        [UserRole.SALES]: [...commonFieldsBeforeApproval, BookingField.CONFIRM],
        [UserRole.SUPER_USER]: [...commonFieldsBeforeApproval, BookingField.CONFIRM, ...targetMarketingFields] // temp add confirm and target marketing fields for uat
    },
    [BookingStatus.PENDING_APPROVAL]: {
        [UserRole.ADMIN]: [...commonFieldsBeforeApproval, BookingField.APPROVE, BookingField.COPY_TM_FIELDS, ...targetMarketingFields],  // temp add approve and target marketing fields for uat
        [UserRole.MS]: [...commonFieldsBeforeApproval, BookingField.APPROVE],
        [UserRole.TM]: [...commonFieldsBeforeApproval, BookingField.COPY_TM_FIELDS, ...targetMarketingFields],
        [UserRole.SALES_ADMIN]: [...commonFieldsBeforeApproval],
        [UserRole.SALES]: [...commonFieldsBeforeApproval],
        [UserRole.SUPER_USER]: [...commonFieldsBeforeApproval, BookingField.APPROVE, ...targetMarketingFields] // temp add approve and target marketing fields for uat
    },
    [BookingStatus.APPROVED]: {
        [UserRole.ADMIN]: [
            BookingField.SAVE,
            BookingField.START_DATE,
            BookingField.END_DATE,
            BookingField.REMARKS,
            BookingField.DETAIL_REQUEST_INVENTORY,
            BookingField.ADD_DETAIL,
            ...targetMarketingFields    // temp add target marketing fields for uat
        ],
        [UserRole.MS]: [
            BookingField.SAVE,
            BookingField.START_DATE,
            BookingField.END_DATE,
            BookingField.REMARKS,
            BookingField.DETAIL_REQUEST_INVENTORY,
            BookingField.ADD_DETAIL,
            BookingField.KEY_RESULT_IMPRESSION
        ],
        [UserRole.TM]: [
            BookingField.SAVE,
            BookingField.START_DATE,
            BookingField.END_DATE,
            BookingField.REMARKS,
            BookingField.DETAIL_REQUEST_INVENTORY,
            BookingField.ADD_DETAIL,
            ...targetMarketingFields
        ],
        [UserRole.SUPER_USER]: [
            BookingField.SAVE,
            BookingField.START_DATE,
            BookingField.END_DATE,
            BookingField.REMARKS,
            BookingField.DETAIL_REQUEST_INVENTORY,
            BookingField.ADD_DETAIL,
            ...targetMarketingFields    // temp add target marketing fields for uat
        ]
    }
}

export function getBookingFieldPermission(status: BookingStatus, role: UserRole): Set<BookingField> {
    return new Set(permissions[status]?.[role] || []);
}

export function isBookingFieldDisabled(status: BookingStatus, role: UserRole, field: BookingField): boolean {
    return status != null && !getBookingFieldPermission(status, role).has(field);
}