import { Component, Inject } from '@angular/core';
import { BookingService } from '../../_service/booking.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BookingTargetingSettingOptionDto, DistinctBookingByColsListItemDto } from '@ims-shared/dto/booking.dto';
import { debounceTime, distinctUntilChanged, of, startWith, switchMap } from 'rxjs';
import { SegmentDto } from '@ims-shared/dto/segment.dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentTagDto } from '@ims-shared/dto/content-tag.dto';

const NaNStringList = ["N/A", "null", "NaN", ""]
@Component({
  selector: 'app-copy-targeting-setting-dialog',
  templateUrl: './copy-targeting-setting-dialog.component.html',
  styleUrls: ['./copy-targeting-setting-dialog.component.css']
})
export class CopyTargetingSettingDialog{

  form: FormGroup = new FormGroup({});
  expiredAudienceTargetingSegments?: (SegmentDto | undefined)[];
  campaignSourceOptions: DistinctBookingByColsListItemDto[] = [];
  audienceTargetingSegmentRefs: Map<string, SegmentDto>
  isloading=true

  get campaignSourceArray(): FormControl {
    return this.form.get('campaignSource') as FormControl;
  }

  get audienceTargetings(): FormArray {
    return this.form.get("audienceTargetings") as FormArray;
  }

  get contentTags(): FormArray {
    return this.form.get("contentTags") as FormArray;
  }

  get keywords(): FormArray {
    return this.form.get("keywords") as FormArray;
  }

  get canCopy(): boolean {
    return (this.audienceTargetings.value.length > 0 && !this.form.controls['copyAudienceTargetings'].disabled && this.form.get('copyAudienceTargetings')?.value) || 
    (this.contentTags.value.length > 0 && !this.form.controls['copyContentTags'].disabled && this.form.get('copyContentTags')?.value) ||  
    (this.keywords.value.length > 0 && !this.form.controls['copyKeywords'].disabled && this.form.get('copyKeywords')?.value)
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CopyTargetingSettingDialog>,
    private bookingService: BookingService
  ){
    this.audienceTargetingSegmentRefs = new Map(
      (data.audienceTargetingSegmentRefs as SegmentDto[]).map(segment => [
        `${segment.criteriaId}_${segment.segmentId}`,
        segment
      ])
    );
    this.initializeForm();
    this.observeCampaignSourceInputChange();
  }

  initializeForm() {
    this.form = new FormGroup({
      campaignSource: new FormControl([], [Validators.required]),
      campaignSourceInput: new FormControl(''),
      copyAudienceTargetings: new FormControl(true),
      audienceTargetings: new FormControl<SegmentDto[]>([]),
      copyContentTags: new FormControl(true),
      contentTags: new FormControl([]),
      copyKeywords: new FormControl(true),
      keywords: new FormControl([], [Validators.maxLength(500)])
    })
  }

  displayCampaignSource(option: BookingTargetingSettingOptionDto): string{
    return option ? `${option.projectCode || "" }\\${option.advertiserName}\\${option.bookingName}` : '';
  }

  displayAudienceTargeting(audienceTargetings: (SegmentDto | undefined)[] | undefined): string {
    if (!audienceTargetings || !audienceTargetings?.length) return '';
    return audienceTargetings.map(segment => 
      segment?.segmentId ?? '' + "_" + segment?.segmentName ?? ''
    ).join(', ');
  }

  displayContentTags(): string {
    const contentTags = (this.contentTags?.value as ContentTagDto[]) || [];
    if (!contentTags.length) return '';
    
    return contentTags.map(contentTag => 
      contentTag.tag
    ).join(', ');
  }

  displayKeywords(): string {
    const Keywords = (this.keywords?.value as string[]) || [];
    if (!Keywords.length) return '';
    
    return Keywords.map(keyword => 
      keyword
    ).join(', ');
  }

  observeCampaignSourceInputChange(){
    this.form.get('campaignSourceInput')!.valueChanges.pipe(
      startWith(''),
      debounceTime(800),
      distinctUntilChanged(),
      switchMap(value => {
        return this.filterCampaignSourceOptions(value)
      })
    ).subscribe(value => {
      this.campaignSourceOptions = value
    })
  }

  filterCampaignSourceOptions(value: string | DistinctBookingByColsListItemDto[] | null) {
    // console.log('value in filterCampaignSourceOptions', value)
    if (!value || typeof value !== 'string') {
      return of([]) 
    }
    const input = value.trim().toLowerCase();
    return this.bookingService.findBookingByColsWithLikeString("id,projectCode,advertiserName,bookingName", "projectCode,advertiserName,bookingName", input ,true)
  }

  getTargetingSettingDetails(option: BookingTargetingSettingOptionDto){
    // console.log('option in getTargetingSettingDetails', option)
    if (this.campaignSourceArray.value.length > 0 || !option.id) return
    this.isloading = false;
    this.form.get('campaignSourceInput')?.setValue('');
    setTimeout(() => {
      this.addCampaignSource(option);
    });
    
    // search for ats_crteria, content_tag, keywords
    this.bookingService.findBookingById(option.id).subscribe(booking => {
      this.isloading = true
      this.form.patchValue(booking);
      // console.log('form.value in getTargetingSettingDetails', this.form.value)
      const audienceTargetings = booking.criteria?.map(criterion => {
        const key = `${criterion.atsCriteriaId}_${criterion.atsSegmentId}`;
        return this.audienceTargetingSegmentRefs.get(key);
      })
      this.expiredAudienceTargetingSegments = booking.criteria?.filter( item => item.expired)?.map(criterion => {
        const key = `${criterion.atsCriteriaId}_${criterion.atsSegmentId}`;
        return this.audienceTargetingSegmentRefs.get(key);
      })
      // console.log("expiredAudienceTargetingSegments: ", this.expiredAudienceTargetingSegments)
      if(audienceTargetings) {
        this.audienceTargetings.setValue(audienceTargetings);
      }
      setTimeout(() => {
        this.updateCopyCheckboxStates();
      });
      
    })
  }

  updateCopyCheckboxStates(){
    const validAudienceTargetings = this.audienceTargetings.value.filter((item: SegmentDto | undefined) => !this.expiredAudienceTargetingSegments?.includes(item))
    const hasAudienceTargetings = (validAudienceTargetings?.length ?? 0) > 0;
    const hasContentTags = (this.contentTags.value?.length ?? 0) > 0;
    const hasKeywords = (this.keywords.value?.length ?? 0) > 0;
    
    // Get the form controls
    const copyAudienceControl = this.form.get('copyAudienceTargetings');
    const copyContentControl = this.form.get('copyContentTags');
    const copyKeywordsControl = this.form.get('copyKeywords');
    
    // Enable/disable copyCheckboxes
    if (hasAudienceTargetings) {
      copyAudienceControl?.enable();
    } else {
      copyAudienceControl?.disable();
    }
    
    if (hasContentTags) {
      copyContentControl?.enable();
    } else {
      copyContentControl?.disable();
    }
    
    if (hasKeywords) {
      copyKeywordsControl?.enable();
    } else {
      copyKeywordsControl?.disable();
    }
  }

  copy(){
    // console.log("form value before emit", this.form.value)
    const copyAudienceControl = this.form.get('copyAudienceTargetings');
    const copyContentControl = this.form.get('copyContentTags');
    const copyKeywordsControl = this.form.get('copyKeywords');
    let setting = this.form.value
    if (!copyAudienceControl?.value){
      delete setting.audienceTargetings;
    }
    if (!copyContentControl?.value){
      delete setting.contentTags;
    }
    if (!copyKeywordsControl?.value){
      delete setting.keywords;
    }
    delete setting.copyAudienceTargetings;
    delete setting.copyContentTags;
    delete setting.copyKeywords;
    delete setting.campaignSourceInput;
    setting.audienceTargetings = setting.audienceTargetings.filter((item: SegmentDto | undefined) => !this.expiredAudienceTargetingSegments?.includes(item))
    const result = (copyAudienceControl?.value || copyContentControl?.value || copyKeywordsControl?.value) ? setting : null
    // console.log("emit result", result)
    this.dialogRef.close(result);
  }

  addCampaignSource(option: BookingTargetingSettingOptionDto) {
    if (this.campaignSourceArray.value.length > 0 ) return 
    this.campaignSourceArray.setValue([option]);
  }

  resetCampaignSource(index: number) {
    this.campaignSourceArray.setValue([]);
  }

}
