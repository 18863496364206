import { Injectable } from "@angular/core";
import { ApiService } from "./api.services";
import { catchError, map, Observable, throwError } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { UserExtensionDto } from "@ims-shared/dto/user.dto";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    headers = new HttpHeaders({
        'Content-Type': 'application/json',
    });
    
    constructor(private apiService: ApiService) { }

    findExtension(): Observable<UserExtensionDto> {
        return this.apiService.get<UserExtensionDto>(`/user/extension`, this.headers).pipe(
            map(res => {
                console.log("res: ", res);
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in findExtension():', error);
                return throwError(() => 'Failed to load extension. Please try again.');
            })
        )
    }

    upsertExtension(extension: string) {
        return this.apiService.post<UserExtensionDto>(`/user/extension`, { extension }, this.headers).pipe(
            map(res => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in updateExtension():', error);
                return throwError(() => 'Failed to update extension. Please try again.');
            })
        )
    }
}