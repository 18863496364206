<mat-dialog-content class="create-dialog-card">
    <h3>Booking Details</h3>
    <mat-divider></mat-divider>
    <div class="create-dialog-card-body mat-typography" [formGroup]="form">
        <div formArrayName="phases">
            <div *ngFor="let phase of phases.controls; let phaseIndex = index" [formGroupName]="phaseIndex"
                class="phase-container">
                <div class="booking-details-date-picker-container" *ngIf="!singlePhase">
                    <h4 class="phase-text">Phase {{phaseIndex + 1}}</h4>
                    <div class="booking-detail-date-picker">
                        <label class="date-picker-label">From</label>
                        <mat-form-field color="primary">
                            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate"
                                (focus)="startDatePicker.open()" [errorStateMatcher]="matcher" [min]="today">

                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="booking-detail-date-picker">
                        <label class="date-picker-label">To</label>
                        <mat-form-field color="primary">
                            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate"
                                (focus)="endDatePicker.open()" [errorStateMatcher]="matcher" [min]="today">

                            <mat-datepicker #endDatePicker></mat-datepicker>

                            <mat-error
                                *ngIf="phases.controls[phaseIndex].get('endDate')?.hasError('invalidDateRange')">
                                Invalid date range
                            </mat-error>

                            <mat-error *ngIf="phases.controls[phaseIndex].get('endDate')?.hasError('overlap')">
                                Date range overlap
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <ng-container *ngIf="!['APPROVED', 'CANCELLED', 'CLOSED'].includes(status)">
                        
                        <button mat-icon-button
                            *ngIf="!isDeletePhaseDisabled(phaseIndex)"
                            (click)="removePhase(phaseIndex)">
                            <mat-icon class="custom-icon-button remove-phase-icon color-error">delete</mat-icon>
                        </button>
                    </ng-container>
                </div>

                <section class="date-picker-checkbox-container ml-2" *ngIf="!singlePhase">
                    <mat-checkbox disableRipple color="primary" class="date-picker-checkbox"
                        formControlName="excludeWeekend">Exclude
                        Weekend</mat-checkbox>
                    <mat-checkbox disableRipple color="primary" class="date-picker-checkbox"
                        formControlName="excludePublicHoliday">Exclude Public
                        Holiday</mat-checkbox>
                </section>

                <table mat-table [dataSource]="getBookingsDataSource(phaseIndex)" class="booking-table"
                    *ngIf="getBookingsDataSource(phaseIndex).data.length > 0" formArrayName="bookings">

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef class="booking-delete-header"></th>
                        <td mat-cell *matCellDef="let booking; let i = index" class="booking-delete-cell">
                            <ng-container *ngIf="!deleteButtonDisabled(phaseIndex, i)">
                                <mat-icon class="custom-icon-button color-error"
                                    (click)="removeBookingDetail(phaseIndex, i)">delete</mat-icon>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="format">
                        <th mat-header-cell *matHeaderCellDef class="booking-format-header">Format</th>
                        <td mat-cell *matCellDef="let booking; let i = index" class="booking-format-cell">
                            <mat-form-field appearance="fill" hideRequiredMarker [formGroupName]="i">
                                <mat-select required class="text-center" [value]="booking.get('format')?.value"
                                    formControlName="format" (ngModelChange)="bookingDetailOnChange(phaseIndex, i)">
                                    <mat-option *ngFor="let format of booking.get('formatOptions')?.value"
                                        [value]="format">{{format.refName}}</mat-option>
                                </mat-select>

                                <mat-error
                                    *ngIf="getBookingDetails(phaseIndex).at(i).get('format')?.hasError('duplicated')">
                                    duplicated detail
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="device">
                        <th mat-header-cell *matHeaderCellDef class="booking-device-header">Device</th>
                        <td mat-cell *matCellDef="let booking; let i = index" class="booking-device-cell">
                            <mat-form-field appearance="fill" hideRequiredMarker [formGroupName]="i">
                                <mat-select required class="text-center" [value]="booking.get('device')?.value"
                                    formControlName="device" (ngModelChange)="bookingDetailOnChange(phaseIndex, i)">
                                    <mat-option *ngFor="let type of booking.get('deviceOptions')?.value"
                                        [value]="type">{{type.refName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="channel">
                        <th mat-header-cell *matHeaderCellDef class="booking-channel-header">Channel</th>
                        <td mat-cell *matCellDef="let booking; let i = index" class="booking-channel-cell">
                            <mat-form-field appearance="fill" hideRequiredMarker [formGroupName]="i">
                                <mat-select required class="text-center" formControlName="channel"
                                    [value]="booking.get('channel')?.value"
                                    (ngModelChange)="bookingDetailOnChange(phaseIndex, i)">
                                    <mat-option *ngFor="let channel of booking.get('channelOptions')?.value"
                                        [value]="channel">{{channel.refName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="requestedInventory">
                        <th mat-header-cell *matHeaderCellDef class="booking-requested-inventory-header">Request
                            Inventory</th>
                        <td mat-cell *matCellDef="let booking; let i = index" class="booking-requested-inventory-cell">
                            <mat-form-field appearance="fill" hideRequiredMarker [formGroupName]="i">
                                <input matInput type="text" class="text-center"
                                    formControlName="tmRequestInventory"
                                    [value]="getBookingRequestInventory(booking) | number: '1.0-0'"
                                    (ngModelChange)="inventoryOnChange(phaseIndex, i, $event)"
                                    (blur)="onFieldBlur(phaseIndex, i)">

                                <mat-error
                                    *ngIf="getBookingDetails(phaseIndex).at(i).get('tmRequestInventory')?.hasError('invalid')">
                                    invalid number
                                </mat-error>

                                <mat-error
                                    *ngIf="getBookingDetails(phaseIndex).at(i).get('tmRequestInventory')?.hasError('required')">
                                    required field
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="booking-status-header">Status</th>
                        <td mat-cell *matCellDef="let booking; let i = index" class="booking-status-cell">
                            <ng-container *ngIf="booking.get('validationStatus')?.value == 'pending'">
                                <mat-spinner diameter="20" strokeWidth="2" class="status-icon"></mat-spinner>
                            </ng-container>
                            <ng-container *ngIf="booking.get('validationStatus')?.value == 'valid'">
                                <mat-icon class="color-success status-icon">check_circle</mat-icon>
                            </ng-container>
                            <ng-container *ngIf="booking.get('validationStatus')?.value == 'invalid'">
                                <div class="status-error-container status-icon" #tooltip="matTooltip"
                                    [matTooltip]="booking.get('message')?.value" matTooltipPosition="left">
                                    <mat-icon class="color-error">cancel</mat-icon>
                                    <span class="color-error error-message">{{booking.get('remainInventory')?.value | number: '1.0-0'}}
                                        available</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="booking.get('validationStatus')?.value == 'warning'">
                                <div class="status-error-container status-icon" #tooltip="matTooltip"
                                    [matTooltip]="booking.get('message')?.value" matTooltipPosition="left">
                                    <mat-icon class="color-warning">error</mat-icon>
                                    <span
                                        class="color-warning warning-message">{{booking.get('shortMessage')?.value}}</span>
                                </div>
                            </ng-container>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="bookingDetailColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: bookingDetailColumns"
                        [class.invalid-booking]="!row.valid">
                    </tr>
                </table>

                <ng-container *ngIf="!addButtonDisabled(phaseIndex)">
                    <button mat-mini-fab disableRipple color="primary" type="button" (click)="addBookingDetail(phaseIndex)"
                        class="booking-add-icon">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="validated">
        <h3 class="summary-header">Format Summary</h3>
        <mat-divider></mat-divider>
        <div class="format-summary-container" *ngIf="validated">
            <div class="create-dialog-card-body mat-typography">
                <table mat-table [dataSource]="summaryDataSource" class="summary-table">
                    <ng-container matColumnDef="format">
                        <th mat-header-cell *matHeaderCellDef>Format</th>
                        <td mat-cell *matCellDef="let row; let i = index">{{row.format}}</td>
                    </ng-container>

                    <ng-container matColumnDef="totalInventory">
                        <th mat-header-cell *matHeaderCellDef>Total Inventory</th>
                        <td mat-cell *matCellDef="let row; let i = index">{{getSummaryTotalInventory(i) | number:'1.0-0'}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="summaryColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: summaryColumns"></tr>
                </table>
            </div>
        </div>
    </div>
</mat-dialog-content>

<button mat-flat-button (click)="addPhase()" class="add-phase-button" color="primary" *ngIf="!singlePhase">Add
    Phase</button>
<div class="dialog-action-container">
    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <div *ngIf="!validateDisabled()">
            <button mat-stroked-button disabledRipple (click)="validateBookingDetails()">Validate</button>
            <button mat-flat-button color="primary" [disabled]="isConfirmButtonDisabled()"
                (click)="submit()">Confirm</button>
        </div>
    </mat-dialog-actions>
</div>