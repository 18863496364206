import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjectiveDto } from '@ims-shared/dto/config.dto';

@Component({
  selector: 'app-objective-edit-dialog',
  templateUrl: './objective-edit-dialog.component.html',
  styleUrls: ['./objective-edit-dialog.component.css']
})
export class ObjectiveEditDialogComponent implements OnInit {
  form: FormGroup;
  isNewObjective: boolean;
  
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ObjectiveEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { objective: Partial<ObjectiveDto> }
  ) {
    this.isNewObjective = !data.objective.objectiveId;
    this.form = this.fb.group({
      description: [data.objective.description || '', [Validators.required]]
    });
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.form.valid) {
      const updatedObjective = {
        ...this.data.objective,
        description: this.form.value.description
      };
      this.dialogRef.close(updatedObjective);
    }
  }
} 