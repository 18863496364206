import { ReferenceDto } from '@ims-shared/dto/reference.dto';
import { Injectable } from '@angular/core';
import { BookingDetailsDailyInquiryDto, BookingDetailsInquiryDto, DistinctBookingByColsListItemDto, QueryBookingDetailsBodyDto } from '@ims-shared/dto/booking.dto';
import { BookingStatus } from '@ims-shared/enum/booking-status';
import { BehaviorSubject, catchError, map, throwError, Observable, of } from 'rxjs';
import { setFormattedBookingStatus } from 'src/util/enumUtil';
import { ApiService } from '../_service/api.services';
import { formatDate } from 'src/util/dateUtil';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { PaginatorDetail } from '../_service/booking.service';
import { _isNumberValue } from '@angular/cdk/coercion';


export interface SearchShowHideBoolean{
  disableSearchBtn: boolean,
  disableExportBtn: boolean,
  showDetailTable: boolean,
  showDetailDialog: boolean,
  isError: boolean,
  noResult: boolean
}

export interface BookingDetailsFormData {
  channel?: ReferenceDto[];
  device?: ReferenceDto[],
  format?: ReferenceDto[],
  bookingStatus: string[];
  bookingStartDate: Date;
  bookingEndDate: Date;
  bookingName?: string | DistinctBookingByColsListItemDto;
  advertiserName?: string[];
  projectCode?: number;
}

const DEFAULT_SHOW_HIDE_CONTROL: SearchShowHideBoolean = {
  disableSearchBtn: false,
  disableExportBtn: false,
  showDetailTable: false,
  showDetailDialog: false,
  isError: false, 
  noResult: false
}

@Injectable({
  providedIn: 'root'
})
export class BookingDetailService {

  constructor(
    private apiService: ApiService,
  ) { 
    this.loadBookingDetailResults();
  }

  tmpResult: BookingDetailsInquiryDto[] = []
  previousFilterFormValue!: BookingDetailsFormData
  previousPaginator!: PaginatorDetail

  private loadBookingDetailResults(): void {
    const savedResults = sessionStorage.getItem('bookingDetailResults');
    if (savedResults) {
      this.tmpResult = JSON.parse(savedResults);
    }
    const savedFilterParams = sessionStorage.getItem('previousFilterFormValue');
    if (savedFilterParams) {
      this.previousFilterFormValue = JSON.parse(savedFilterParams);
    }
    const savedPaginator = sessionStorage.getItem('paginator');
    if (savedPaginator) {
      this.previousPaginator = JSON.parse(savedPaginator);
    }
  }

  getSearchResults(): Observable<BookingDetailsInquiryDto[]| []> {
    return of(this.tmpResult);
  }

  getpreviousFilterFormValue(): Observable<BookingDetailsFormData> {
    return of(this.previousFilterFormValue);
  }

  getpreviousPaginator(): Observable<PaginatorDetail> {
    return of(this.previousPaginator);
  }

  setSearchResults(results: BookingDetailsInquiryDto[]| []): void {
    sessionStorage.setItem('bookingDetailResults', JSON.stringify(results));
    this.tmpResult = results;
  }

  setPreviousFilter(formData: BookingDetailsFormData): void {
    sessionStorage.setItem('previousFilterFormValue', JSON.stringify(formData));
    this.previousFilterFormValue = formData
  }

  setPreviousPaginator(paginator: MatPaginator): void {
    const detail = { pageIndex: paginator.pageIndex, pageSize: paginator.pageSize, length: this.tmpResult.length }
    sessionStorage.setItem('paginator', JSON.stringify(detail));
    this.previousPaginator = detail
  }

  showHideControl = new BehaviorSubject<SearchShowHideBoolean>(DEFAULT_SHOW_HIDE_CONTROL);

  _showHideControl$ = this.showHideControl.asObservable();

  updateShowHideControl(newValue: Partial<SearchShowHideBoolean>) {
    const currentValue = this.showHideControl.getValue();
    const updatedValue = {
      ...currentValue,
      ...newValue
    };
    // console.log("updateShowHideControl: ", updatedValue)
    this.showHideControl.next(updatedValue);
  }

  resetShowHideControl(){
    this.updateShowHideControl(DEFAULT_SHOW_HIDE_CONTROL);
  }
  
  findBookingDetail(form: BookingDetailsFormData){
    this.setPreviousFilter(form);
    const apiPath = "/booking/detail"
    let body: QueryBookingDetailsBodyDto = {} as any;
    const { projectCode, bookingName, bookingStartDate, bookingEndDate, format, channel, device, bookingStatus, advertiserName} = form 
    if (projectCode || bookingName) {
      if (bookingName){
        body.bookingName = (typeof bookingName !== "string" ? bookingName.bookingName : bookingName) ?? '';
      }
      if (_isNumberValue(projectCode)) {
        body.projectCode = `${projectCode?.toString()}`;
      }
      else{
        body.projectCode = ``;
      }
    }
    else{
      body = {
        format:format,
        device: device,
        channel: channel,
        bookingStartDate: formatDate(bookingStartDate, 'YYYY-MM-DD'),
        bookingEndDate: formatDate(bookingEndDate, 'YYYY-MM-DD'),
        bookingStatus: bookingStatus.map(status => setFormattedBookingStatus(status) as BookingStatus),
        advertiserName: advertiserName
      } as QueryBookingDetailsBodyDto
    }
    
    return this.apiService.post<BookingDetailsInquiryDto[]>(apiPath, body).pipe(
      map(res => {
        return res;
      }),
      catchError((error) => {
        console.error('Error occurred in findBookingDetail():', error);
        this.updateShowHideControl({
          disableSearchBtn: false,
          disableExportBtn: false,
        })
        return throwError(() => 'Failed to find booking detail. Please try again.');
      }))
  }

  findBookingDetailDaily(id: number){
    const apiPath = "/booking/dailyDetail"
    
    return this.apiService.get<BookingDetailsDailyInquiryDto[]>(apiPath, undefined, {id: id}).pipe(
      map(res => {
        return res;
      }),
      catchError((error) => {
        console.error('Error occurred in findBookingDetailDaily():', error);
        this.updateShowHideControl({
          showDetailDialog: false
        })
        return throwError(() => 'Failed to find booking daily detail. Please try again.');
      }))
  }

  exportBookingDetailCsv(form: BookingDetailsFormData){
    this.setPreviousFilter(form);
    const apiPath = "/booking/detail/export/csv"
    let body: QueryBookingDetailsBodyDto = {};
    const { projectCode, bookingName, bookingStartDate, bookingEndDate, format, channel, device, bookingStatus, advertiserName} = form 
    if (projectCode || bookingName) {
      if (bookingName) {
        body.bookingName = (typeof bookingName !== "string" ? bookingName.bookingName : bookingName) ?? '';
      }
      if (projectCode) {
        body.projectCode = `${projectCode}`;
      }
    }
    else{
      body = {
        format:format,
        device: device,
        channel: channel,
        bookingStartDate: formatDate(bookingStartDate, 'YYYY-MM-DD'),
        bookingEndDate: formatDate(bookingEndDate, 'YYYY-MM-DD'),
        bookingStatus: bookingStatus.map(status => setFormattedBookingStatus(status) as BookingStatus),
        advertiserName: advertiserName
      }
    }
    return this.apiService.post<Blob>(apiPath, body, undefined, undefined, 'blob' as 'json').pipe(
      map(res => {
        // console.log(`exportBookingDetailCsv() res: ${res}`)
        return res
      }),
      catchError((error) => {
        console.error('Error occurred in exportBookingDetailCsv():', error);
        this.updateShowHideControl({
          disableSearchBtn: false,
          disableExportBtn: false,
        })
        return throwError(() => 'Failed to export booking detail csv. Please try again.');
      })
    )
  }
}

export function bookingStatusValidation(formGroup: FormGroup): ValidationErrors | null {
  const bookingStatusControl = formGroup.get('bookingStatus');
  if (formGroup.get('bookingName')?.value ) {
    bookingStatusControl?.setErrors(null);
    return null
  }
  if (!bookingStatusControl) {
    formGroup.get('bookingStatus')?.setErrors({ required: true });
    return { required: true };
  }

  const bookingStatus = bookingStatusControl.value;

  if (!Array.isArray(bookingStatus) || bookingStatus.length === 0) {
    formGroup.get('bookingStatus')?.setErrors({ required: true });
    return { required: true };
  }
  formGroup.get('bookingStatus')?.setErrors(null);
  bookingStatusControl.setErrors(null);
  return null;
}

