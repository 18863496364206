<div class="container ims-container" [formGroup]="form">
    <div class="input-group">
        <div class="filter_cond_btn"  >
            <a routerLink="/booking/create">
                <button mat-fab extended color="primary">
                    New Inventory Booking
                </button>
            </a>
        </div>
        <div class="searchbox-bar">
            <div class="input-group-prepend">
                <span class="input-group-text search-box-icon">
                    <fa-icon class="segment-icon-prefix" [icon]="['fas', 'search']"></fa-icon>
                </span>
            </div>
            <input class="form-control searchbox-form-field" formControlName="searching" matInput id="input-audience-search" type="text" (keydown.enter)="applyFilter()"/>
        </div>

        <div class="filter_cond_s" *ngIf="user">
            <div class="filter_cond_title">Campaign Type</div>
            <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-select class="filter_cond_field" [class.readonly-element]="user.role === userRoleList.TM" formControlName="bookingType" panelClass="select-panel">
                    <mat-select-trigger>
                        <span class="filter_cond_selected_value">{{ form.get('bookingType')?.value }}</span>
                        <mat-icon [class.d-none]="user.role === userRoleList.TM" class="filter_cond_field_arrow">keyboard_arrow_down</mat-icon>
                    </mat-select-trigger>
                    <mat-option *ngFor="let bookingType of BookingTypes" [value]="bookingType" [matTooltip]="bookingType" matTooltipPosition="right">
                        {{ bookingType }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="filter_cond_s" *ngIf="user">
            <div class="filter_cond_title">Channel (BU)</div>
            <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-select class="filter_cond_field" formControlName="targetBu" panelClass="select-panel">
                    <mat-select-trigger>
                        <span class="filter_cond_selected_value">{{ form.get('targetBu')!.value?.refName ?? "ALL"}}</span>
                        <mat-icon class="filter_cond_field_arrow">keyboard_arrow_down</mat-icon>
                    </mat-select-trigger>
                    <mat-option value="" matTooltip="ALL" matTooltipPosition="right">
                        ALL
                    </mat-option>
                    <mat-option *ngFor="let channel of rootChannelOptions" [value]="channel" [matTooltip]="channel.refName" matTooltipPosition="right">
                        {{ channel.refName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
        <div class="filter_cond_m">
            <div class="filter_cond_title">Start Date</div>
            <mat-form-field class="filter_cond_field filter_date_field" color="primary">
                <input matInput [matDatepicker]="datepicker" formControlName="startDate" (change)="parseDate($event)" placeholder="ALL" [readonly]="true" (click)="datepicker.open()">
                <mat-datepicker-toggle matIconSuffix  [for]="datepicker">
                    <mat-icon disableRipple class="filter_date_field_arrow" matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #datepicker disabled="false">
                    <mat-datepicker-actions>
                        <button disableRipple mat-button matDatepickerCancel (click)="resetStartDate()">Reset</button>
                        <button disableRipple mat-raised-button color="primary" matDatepickerApply>Confirm</button>
                    </mat-datepicker-actions>
                </mat-datepicker>
            </mat-form-field>
        </div>

        <div class="filter_cond_m">
            <div class="filter_cond_title">End Date</div>
            <mat-form-field class="filter_cond_field filter_date_field" color="primary">
                <input matInput [min]="endDateFilterLimit"  [matDatepicker]="endDatepicker" formControlName="endDate" (change)="parseDate($event)" placeholder="ALL" [readonly]="true" (click)="endDatepicker.open()">
                <mat-datepicker-toggle matIconSuffix  [for]="endDatepicker">
                    <mat-icon disableRipple class="filter_date_field_arrow" matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #endDatepicker disabled="false">
                    <mat-datepicker-actions>
                        <button disableRipple mat-button matDatepickerCancel (click)="resetEndDate()">Reset</button>
                        <button disableRipple mat-raised-button color="primary" matDatepickerApply (click)="onEndDateChange($event)">Confirm</button>
                    </mat-datepicker-actions>
                </mat-datepicker>
            </mat-form-field>
        </div>

        <div class="filter_cond_l" *ngIf="user">
            <div class="filter_cond_title">Created by</div>
            <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-select class="filter_cond_field" formControlName="createdBy" panelClass="select-panel" >
                    <mat-select-trigger>
                        <span class="filter_cond_selected_value">{{ form.get('createdBy')?.value }}</span>
                        <mat-icon class="filter_cond_field_arrow">keyboard_arrow_down</mat-icon>
                    </mat-select-trigger>
                    <mat-option *ngFor="let creator of creatorOptions" [value]="creator" [matTooltip]="creator" matTooltipPosition="right">
                    {{ creator }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="filter_cond_xl">
            <div class="filter_cond_title">Booking Status</div>
            <mat-form-field appearance="fill" hideRequiredMarker>
                <mat-select class="filter_cond_field" formControlName="bookingStatus" panelClass="select-panel">
                    <mat-select-trigger>
                        <span class="filter_cond_selected_value">{{ form.get('bookingStatus')?.value }}</span>
                        <mat-icon class="filter_cond_field_arrow">keyboard_arrow_down</mat-icon>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of bookingStatusOptions" [value]="status" [matTooltip]="status" matTooltipPosition="right">
                    {{ status }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
        <div >
            <button class="filter_cond_btn" mat-fab extended color="primary" (click)="applyFilter()">
                Search
            </button>
        </div>
    </div>

    <div class="card mat-elevation-z8 mt-3 listing-container">
        <table class="listing-table" mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="findAllBookingsWithSorting($event)">

            <ng-container matColumnDef="projectCode">
                <th class="table-header-booking-id" mat-header-cell *matHeaderCellDef mat-sort-header>Project Code</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Booking Project Code'"> 
                    <ng-container *ngIf="element">
                        <div class="cell-wrapper" *ngIf="element.projectCode; else showNA">
                            {{element.projectCode}} 
                        </div>
                        <ng-template #showNA>
                            <div class="cell-wrapper">
                                N/A 
                            </div>
                        </ng-template>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="advertiserName">
                <th class="table-header-advertiserName" mat-header-cell *matHeaderCellDef mat-sort-header>Advertiser</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Advertiser'"> 
                    <div class="cell-wrapper" *ngIf="element">
                      {{element.advertiserName}} 
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="bookingName">
                <th class="table-header-booking-name" mat-header-cell *matHeaderCellDef mat-sort-header>Campaign Name</th>
                <td class="table-cell-bookingName" mat-cell *matCellDef="let element" [attr.data-column-name]="'Campaign Name'"> 
                    <div class="cell-wrapper" *ngIf="element">
                      {{element.bookingName}} 
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="bookingType">
                <th class="table-header-booking-type" mat-header-cell *matHeaderCellDef mat-sort-header>Campaign Type</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Campaign Type'"> 
                    <ng-container *ngIf="element">
                        <div class="cell-wrapper" *ngIf = "element.isTm;else nonTM">
                            TM 
                          </div>
                          <ng-template #nonTM>
                              <div class="cell-wrapper">
                                  Non-TM 
                              </div>
                          </ng-template>
                    </ng-container>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="startDate">
                <th class="table-header-start-date" mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Start Date'"> 
                    <div class="cell-wrapper" *ngIf="element?.bookingDetails[0]?.startDate; else noBookingDetails">
                        {{convertDate(element.bookingDetails[0].startDate)}}
                    </div>
                    <ng-template #noBookingDetails>
                        NULL
                    </ng-template>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="endDate">
                <th class="table-header-end-date" mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'End Date'"> 
                    <div class="cell-wrapper" *ngIf="element?.bookingDetails[0]?.endDate; else noBookingDetails">
                        {{convertDate(element.bookingDetails[0].endDate)}}
                    </div>
                    <ng-template #noBookingDetails>
                        NULL
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <th class="table-header-created-by" mat-header-cell *matHeaderCellDef mat-sort-header>Created by</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Created by'"> 
                    <div class="cell-wrapper" *ngIf="element">
                        {{element.creatorName}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="createdDate">
                <th class="table-header-created-by" mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Created Date'"> 
                    <div class="cell-wrapper" *ngIf="element">
                        {{parseCreatedDate(element.createdDate)}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="bookingStatus">
                <th class="table-header-booking-status" mat-header-cell *matHeaderCellDef mat-sort-header>Booking Status</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Booking Status'"> 
                    <div class="cell-wrapper" *ngIf="element && (showBookingStatusErrorToolTip(element.bookingGamOrder)===' Please try again' || !showBookingStatusErrorToolTip(element.bookingGamOrder)); else errorStatus">
                        {{getbookingStatusEnum(element.status)}}
                    </div>
                    <ng-template #errorStatus>
                        <div *ngIf="element && element.bookingGamOrder.errorStatus; else lineItemError" class="d-center p-booking-status-error" matTooltip="Create order failed!! Please try again" matTooltipPosition="right">
                            <div class="cell-wrapper">
                                {{getbookingStatusEnum(BookingStatus.ERROR_GAM)}}
                            </div>
                            <button class="ims-red" mat-icon-button >
                                <mat-icon>info</mat-icon>
                            </button>
                        </div>
                        <ng-template #lineItemError>
                            <div *ngIf="element" class="d-center p-booking-status-error" [matTooltip]="showBookingStatusErrorToolTip(element.bookingGamOrder)" matTooltipPosition="right">
                                <div class="cell-wrapper">
                                    {{getbookingStatusEnum(BookingStatus.ERROR_GAM)}}
                                </div>
                                <button class="ims-red" mat-icon-button >
                                    <mat-icon>info</mat-icon>
                                </button>
                            </div>
                        </ng-template>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th class="table-header-actions" mat-header-cell *matHeaderCellDef></th>
                <td class="listing-action-btn" mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element">
                        <ng-container *ngIf="!isInnerWidth1150();else actionMenu">
                            <button class="ims-grey-blue" [routerLink]="['/booking', element.id]" mat-button>View</button>
                            <ng-container *ngIf="disableEditBtnCheck(element); else enabledEdit">
                                <button class="ims-pale-green" mat-button disabled>Edit</button>
                            </ng-container>
                            <ng-container *ngIf="disableCancelBtnCheck(element); else enabledCancel">
                                <button class="ims-red-pink" mat-button disabled>Cancel</button>
                            </ng-container>
                        </ng-container>
                        <ng-template #actionMenu>
                            <button color="primary" mat-icon-button [matMenuTriggerFor]="menu" aria-label="routing page menu">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button class="ims-grey-blue" (click)="navigateViewPage(element.id)" mat-button>View</button>
                                <ng-container *ngIf="disableEditBtnCheck(element); else enabledEdit">
                                    <button class="ims-pale-green" mat-button disabled>Edit</button>
                                </ng-container>
                                <ng-container *ngIf="disableCancelBtnCheck(element); else enabledCancel">
                                    <button class="ims-red-pink" mat-button disabled>Cancel</button>
                                </ng-container>
                            </mat-menu>
                        </ng-template>
    
                        <ng-template #enabledEdit>
                            <button class="ims-pale-green" mat-button (click)="editBooking(element.id)">Edit</button>
                        </ng-template>
            
                        <ng-template #enabledCancel>
                            <button class="ims-red-pink" mat-button (click)="cancelBooking(element)">Cancel</button>
                        </ng-template>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="inventory-row" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator class="custom-pagination" [pageSizeOptions]="pageSizeOpt"
                        showFirstLastButtons
                        [length]="noOfTotalBooking"
                        (page)="onPageChange($event)"
                        aria-label="Select page of bookings">
        </mat-paginator>
    </div>
</div>