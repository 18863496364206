import { UserRole } from '@ims-shared/enum/user-role';
import { DeviceType } from '@ims-shared/enum/device-type';
import { AdSize } from '@ims-shared/enum/ad-size';
import { Channel } from './../../../../shared/src/enum/channel';
import { ReferenceDto } from '@ims-shared/dto/reference.dto';
import { MatSelectChange } from '@angular/material/select';
import { Config, ObjectiveDto } from '@ims-shared/dto/config.dto';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { catchError, map, Subject, takeUntil, throwError, Observable, forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { ObjectiveService } from '../_service/objective.service';
import { HttpParams } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { BookingService } from '../_service/booking.service';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ObjectiveEditDialogComponent } from './objective-edit-dialog/objective-edit-dialog.component';
import { ObjectiveDeleteDialogComponent } from './objective-delete-dialog/objective-delete-dialog.component';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit{
  form!: FormGroup
  private destroy$ = new Subject<void>();

  devices = [
    {
      key: "app",
      name: "App/Mobile Web",
    },
    {
      key: "desktop",
      name: "Desktop Web"
    }
  ]

  channels = [
    {
      key: "ul",
      name: "U Lifestyle"
    }, 
    {
      key: "iet",
      name: "iET"
    }
  ]
  public tableHeight!: string;
  adSizeConfigs!: ReferenceDto[]
  deviceRefs!: ReferenceDto[]
  channelRefs!: ReferenceDto[]

  freq_list = [{name: "App", control: "freq_app"}, {name:  "Mobile Web", control: "freq_mobile_web"}, {name:  "Desktop Web", control: "freq_desktop_web"}]
  freq_time_list = ["hour", "minute"]
  percentage_table_breakpoint !: number
  objectives: ObjectiveDto[] = [];
  originalObjectives: ObjectiveDto[] = [];
  objectivesChanged: boolean = false;
  private draggedIndex: number | null = null;
  private draggedElement: HTMLElement | null = null;
  isDeleteObjectiveButtonDisabled: boolean = false;

  @ViewChildren('matError') 
  matErrors!: QueryList<ElementRef>;

  selectedTabIndex = 0;

  userRole: UserRole | undefined;

  constructor(
    private location: Location,
    private configurationService: ConfigurationService,
    private bookingService: BookingService,
    private authService: AuthService,
    private objectiveService: ObjectiveService,
    private dialog: MatDialog
  ) {
    this.percentage_table_breakpoint = 3;
  }
  
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.setBreakpoint();
    this.initiateAdSizeConfigsAndFormGp();
    this.fetchObjective();
    this.authService.getUserInfo().subscribe(userInfo => {
      this.userRole = userInfo.role;
      console.log("userRole: ", this.userRole)
    })
  }

  initiateAdSizeConfigsAndFormGp() {
    const getReferences$ = this.bookingService.findAllReferences();
    const getAllConfigPercentage$ = this.configurationService.getConfigDetail();
    forkJoin([getReferences$, getAllConfigPercentage$])
    .subscribe({
      next:([references, configPercentage]) => {
        console.log("res of getConfigDetail(): ", configPercentage)
        this.adSizeConfigs = references.filter(reference => reference.refType === 'format' ); // && reference.refName !== AdSize.BILLBOARD && reference.refName !== AdSize.SECTION_SPLASH 
        this.deviceRefs = references.filter(reference => reference.refType === 'device');
        this.channelRefs = references.filter(reference => reference.refType === 'channel');
        console.log("res of adSizeConfigs(): ", this.adSizeConfigs)
        this.tableHeight = ((this.adSizeConfigs.length * 100 + 20 )).toString() + 'px' 
        console.log("tableHeight: ", this.tableHeight)
        this.form = this.createForm();
        if (configPercentage){
          this.form.patchValue(configPercentage);
        }
        this.initiateFreqCapUnit();
        console.log("this.form value after initate: ", this.form.value)
      },
      error: (error) => {
        console.error('An error occurred:', error);
      },
      complete: () => {
      }
    });
  }

  fetchObjective() {
    this.objectiveService.findObjectives().subscribe(objectives => {
      this.objectives = objectives;
      this.originalObjectives = JSON.parse(JSON.stringify(objectives));
      this.objectivesChanged = false;
    });
  }

  createForm(): FormGroup {
    const group: Record<string, FormGroup| FormControl> = {};

    this.devices.map(device => {
      const appDeviceRef: ReferenceDto[] = this.deviceRefs.filter(reference => reference.refName === DeviceType.APP || reference.refName === DeviceType.MOBILE_WEB);
      const desktopDeviceRef: ReferenceDto[] = this.deviceRefs.filter(reference => reference.refName === DeviceType.DESKTOP_WEB);
      const deviceRef = device.key.toLowerCase() === DeviceType.APP.toLowerCase()? appDeviceRef : desktopDeviceRef
      this.adSizeConfigs.map(adSize => {
        const controlName = `${device.key}_${adSize.refName}`;
        const ulChannelRef = this.channelRefs.filter(reference => reference.refName === Channel.UL)[0];
        const ietChannelRef = this.channelRefs.filter(reference => reference.refName === Channel.IET)[0];
        group[controlName] = new FormGroup({
          ul: new FormGroup({
            percentage: new FormControl(100, [
              Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/),
              Validators.required,
              Validators.min(0)
            ]),
            device: new FormControl(deviceRef),
            format: new FormControl(adSize),
            channel: new FormControl(ulChannelRef)
          }),
          iet: new FormGroup({
            percentage: new FormControl(100, [
              Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/),
              Validators.required,
              Validators.min(0)
            ]),
            device: new FormControl(deviceRef),
            format: new FormControl(adSize),
            channel: new FormControl(ietChannelRef)
          }),
        });
        const publicHolidayControlName = `${device.key}_${adSize.refName}_holiday`;
        group[publicHolidayControlName] = new FormGroup({
          ul: new FormGroup({
            percentage: new FormControl(100, [
              Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/),
              Validators.required,
              Validators.min(0)
            ]),
            device: new FormControl(deviceRef),
            format: new FormControl(adSize),
            channel: new FormControl(ulChannelRef)
          }),
          iet: new FormGroup({
            percentage: new FormControl(100, [
              Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/),
              Validators.required,
              Validators.min(0)
            ]),
            device: new FormControl(
              device.key.toLowerCase() === DeviceType.APP.toLowerCase()? appDeviceRef : desktopDeviceRef
            ),
            format: new FormControl(adSize),
            channel: new FormControl(ietChannelRef)
          })
        });
      });
    });
    ['freq_app', 'freq_mobile_web', 'freq_desktop_web'].forEach(control => {
      group[control] = new FormGroup({
        hour: new FormControl<number| null>(null, [Validators.pattern(/^[0-9]+$/), Validators.required]),
        minute: new FormControl<number| null>(null, [Validators.pattern(/^[0-9]+$/), Validators.required]),
        impressions: new FormControl<number| null>(null, [Validators.pattern(/^[0-9]+$/), Validators.required])
      }, this.requiredValidator());
      group[`${control}_time_unit`] = new FormControl(undefined);
    });

    return new FormGroup(group);
  }

  onResize(event: Event) {
    this.setBreakpoint();
  }

  private setBreakpoint(): void {
    this.percentage_table_breakpoint = window.innerWidth <= 767 ? 1 : 20;
  }

  save(){
    console.log("save() is triggered !!")
    this.form.updateValueAndValidity(); // validate the form again after updated publish attr
    if (!this.form.valid) {
      this.exe_search_scrollTop();
      return;
    }
    let formValues = this.form.value;
    let freqcapValue = {
      freq_app: formValues.freq_app,
      freq_mobile_web: formValues.freq_mobile_web,
      freq_desktop_web: formValues.freq_desktop_web
    };
    delete formValues.freq_app_time_unit
    delete formValues.freq_mobile_web_time_unit
    delete formValues.freq_desktop_web_time_unit
    delete formValues.freq_app
    delete formValues.freq_mobile_web
    delete formValues.freq_desktop_web
    let bufferData = {
      ...formValues
    }
    console.log("bufferData in save(): ", bufferData)
    console.log("freqcapValue in save(): ", freqcapValue)
    const freqcapDto = this.configurationService.mapFreqcapValueWithRef(freqcapValue, this.adSizeConfigs, this.deviceRefs)
    const configBufferPercentage$ = this.configurationService.postCreateOrUpdateBufferConfig(bufferData);
    const configFreqcapPercentage$ = this.configurationService.postCreateOrUpdateFreqCapConfig(freqcapDto);
    forkJoin([configBufferPercentage$, configFreqcapPercentage$])
    .subscribe({
      next:([configBufferPercentage, configFreqcapPercentage]) => {

      },
      error: (error) => {
        console.error('An error occurred:', error);
        if (confirm("Save Failed")){
          window.location.reload();
        }
      },
      complete: () => {
        window.location.reload();
      }
    });
  }

  saveObjective() {
    this.objectiveService.updateObjective(this.objectives).subscribe({
      next: (updatedObjectives) => {
        this.objectives = updatedObjectives;
        this.originalObjectives = JSON.parse(JSON.stringify(updatedObjectives));
        this.objectivesChanged = false;
      },
      error: (error) => {
        console.error('Error updating objectives:', error);
      }
    })
  }

  isNextToFieldError(formFieldName: string) {
    let formField = formFieldName + '.'+ "ul"
    this.form.updateValueAndValidity();
    let control = this.form.get(formField) 
    // console.log("formField isNextToFieldError:", formField);
    if (control && control.value) {
      // console.log("ul error control value:", control?.errors);
      // console.log("iet error control value:", this.form.get(formFieldName + '.'+ "iet")?.errors);
      // console.log("return value:", this.form.get(formField)?.errors ? true :  false);
    }
    return this.form.get(formField)?.errors ? true :  false
  }

  parsePercentageToNumber(formFieldName: string, channel: string){
    let formField = formFieldName + '.'+ channel +'.percentage'
    let control = this.form.get(formField) 
    console.log("formField Converted numeric value:", formField);
    if (control && control.value) {
      const numericValue = parseFloat(control.value);
      // console.log("Converted numeric value:", numericValue); // Debugging line
      control.setValue(numericValue, { emitEvent: false });
      // this.form.updateValueAndValidity();
      // console.log("Updated form control value:", control.value); // Debugging line
    }
  }

  exe_search_scrollTop() {
    setTimeout(() => {
      const errorElement = this.matErrors.find(error => error.nativeElement.offsetParent.offsetParent.offsetParent.offsetParent.offsetParent !== null);

      if (errorElement) {
        errorElement.nativeElement.scrollIntoView({behavior: "smooth"});
      }
    });
  }

  onFreqCapTimeUnitChange(fieldName: string, event: MatSelectChange) {
    const time_unit = event.value;
    const group = this.getSubFormGroup(fieldName);
    const currentControl = group.get('hour') ? "hour": group.get('minute') ? "minute": "";
    // console.log("currentControl going to be removed in onFreqCapChange", currentControl)
    if (currentControl) {
      group.removeControl(currentControl)
      group.removeControl("impressions")
    }

    group.get(fieldName + "_time_unit")?.setValue(time_unit);
    group.addControl(time_unit, new FormControl<number>(1, [Validators.pattern(/^[0-9]+$/), Validators.required]));
    group.addControl("impressions", new FormControl<number>(1, [Validators.pattern(/^[0-9]+$/), Validators.required]));
    console.log("group value after update in onFreqCapChange", group.value)
    group.markAsTouched();
  }

  requiredValidator(): Validators {
    return (control: AbstractControl): ValidationErrors | null => {
      const group = control as FormGroup;
      const hour = group.get('hour')?.value;
      const minute = group.get('minute')?.value;
      const valid = ((Number.isInteger(hour) && hour > -1)) || ((Number.isInteger(minute) && minute > -1));
      // console.log(`Validator called: hour = ${hour}, minute = ${minute}, valid = ${valid}, group =${group.errors}`);
      return valid ? null : { 'timeFieldsRequired': true };
    };
  }

  getSubFormGroup(subFormGroupName: string){
    return (this.form.get(subFormGroupName) as FormGroup)
  }

  parseFreqCapToNumber(subFormFieldName: string, time_unit: string, event: Event){
    let control = this.getSubFormGroup(subFormFieldName)
    let value = (event.target as HTMLInputElement).value
    // console.log("formField Converted numeric value parseFreqCapToNumber:", formField);
    console.log("event parseFreqCapToNumber:", (event.target as HTMLInputElement).value);
    if (value) {
      try{
        const numericValue = parseInt(value);
        console.log("Converted impressions numeric value:", numericValue, time_unit); // Debugging line
        // control.get(time_unit)?.setValue(numericValue, { emitEvent: false });
        control.get('impressions')?.setValue(numericValue, { emitEvent: false });
        control.markAsTouched();
      }
      catch(e){
        control.get(time_unit)?.setValue(undefined, { emitEvent: false });
        control.get('impressions')?.setValue(undefined, { emitEvent: false });
      }
      this.form.updateValueAndValidity();
      console.log("Updated form control value in parseFreqCapToNumber:", this.form.value); // Debugging line
    }
  }

  parseFreqCapTimeUnitToNumber(subFormFieldName: string, time_unit: string, event: Event){
    let control = this.getSubFormGroup(subFormFieldName)
    let value = (event.target as HTMLInputElement).value
    // console.log("formField Converted numeric value parseFreqCapTimeUnitToNumber:", formField);
    console.log("event parseFreqCapTimeUnitToNumber:", (event.target as HTMLInputElement).value);
    if (value) {
      try{
        const numericValue = parseInt(value);
        console.log("Converted numeric value:", numericValue, time_unit); // Debugging line
        control.get(time_unit)?.setValue(numericValue, { emitEvent: false });
        control.markAsTouched();
      }
      catch(e){
        control.get(time_unit)?.setValue(undefined, { emitEvent: false });
      }
      this.form.updateValueAndValidity();
      console.log("Updated form control value in parseFreqCapTimeUnitToNumber:", this.form.value); // Debugging line
    }
  }

  initiateFreqCapUnit(){
    ['freq_app', 'freq_mobile_web', 'freq_desktop_web'].forEach(control => {
      let hourValue = this.getSubFormGroup(control).get("hour")?.value
      let minuteValue = this.getSubFormGroup(control).get("minute")?.value
      let impressions = this.getSubFormGroup(control).get("impressions")?.value
      if (hourValue){
        this.form.get(control + "_time_unit")?.setValue("hour")
        this.getSubFormGroup(control).removeControl("minute");
      }
      else if (minuteValue){
        this.form.get(control + "_time_unit")?.setValue("minute")
        this.getSubFormGroup(control).removeControl("hour");
      }
      else{
        this.getSubFormGroup(control).get("hour")?.setValue(1)
        this.form.get(control + "_time_unit")?.setValue("hour")
        this.getSubFormGroup(control).removeControl("minute");
      }
      if (!impressions){
        this.getSubFormGroup(control).get("impressions")?.setValue(1)
      }
    });
  }

  editObjective(objective: ObjectiveDto, index: number): void {
    const dialogRef = this.dialog.open(ObjectiveEditDialogComponent, {
      width: '500px',
      data: { objective }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.objectives[index] = result;
        this.checkObjectivesChanged();
      }
    });
  }

  deleteObjective(objective: ObjectiveDto): void {
    const dialogRef = this.dialog.open(ObjectiveDeleteDialogComponent, {
      width: '500px',
      data: { objective }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isDeleteObjectiveButtonDisabled = true;
        this.objectiveService.deleteObjective(objective.objectiveId).subscribe({
          next: () => {
            this.objectives = this.objectives.filter(o => o.objectiveId !== objective.objectiveId);
            this.checkObjectivesChanged();
            this.isDeleteObjectiveButtonDisabled = false;
          },
          error: (error) => {
            console.error('Error deleting objective:', error);
            this.isDeleteObjectiveButtonDisabled = false;
          }
        });
      }
    });
  }

  createObjective(): void {
    const newObjective: Partial<ObjectiveDto> = {
      description: ''
    };
    
    const dialogRef = this.dialog.open(ObjectiveEditDialogComponent, {
      width: '500px',
      data: { objective: newObjective }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.objectiveService.createObjective(result).subscribe({
          next: (createdObjective) => {
            console.log("createdObjective: ", createdObjective)
            this.objectives.push(createdObjective);
            this.checkObjectivesChanged();
          },
          error: (error) => {
            console.error('Error creating objective:', error);
          }
        });
      }
    });
  }

  onTabChange(event: any): void {
    this.selectedTabIndex = event.index;
  }

  onDragStart(event: DragEvent, index: number): void {
    this.draggedIndex = index;
    if (event.dataTransfer) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('text/plain', index.toString());
    }
    
    // Store the dragged element reference
    this.draggedElement = event.target as HTMLElement;
    
    // Add a class to the dragged element to style it
    setTimeout(() => {
      if (this.draggedElement) {
        this.draggedElement.classList.add('dragging');
      }
    }, 0);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    if (!event.dataTransfer) return;
    
    event.dataTransfer.dropEffect = 'move';
    
    const target = this.getDropTarget(event.target as HTMLElement);
    
    // Find all card elements
    const cards = Array.from(document.querySelectorAll('.objective-card'));
    
    // Remove hover classes from all cards
    cards.forEach(card => {
      card.classList.remove('drag-hover');
    });
    
    // Add hover class to current target
    if (target) {
      target.classList.add('drag-hover');
    }
  }

  onDrop(event: DragEvent, dropIndex: number): void {
    event.preventDefault();
    
    if (this.draggedIndex === null || this.draggedIndex === dropIndex) return;
    
    const target = this.getDropTarget(event.target as HTMLElement);
    if (target) {
      target.classList.remove('drag-hover');
    }
    
    // Perform the reordering
    const draggedItem = this.objectives[this.draggedIndex];
    const newObjectives = [...this.objectives];
    
    // Remove from original position
    newObjectives.splice(this.draggedIndex, 1);
    
    // Add at new position
    newObjectives.splice(dropIndex, 0, draggedItem);
    
    // Update the objectives array
    this.objectives = newObjectives;
    
    // Check if the order has changed from the original
    this.checkObjectivesChanged();
  }

  onDragEnd(): void {
    // Remove dragging class from element
    if (this.draggedElement) {
      this.draggedElement.classList.remove('dragging');
    }
    
    // Reset dragged references
    this.draggedIndex = null;
    this.draggedElement = null;
    
    // Remove hover classes from all cards
    const cards = Array.from(document.querySelectorAll('.objective-card'));
    cards.forEach(card => {
      card.classList.remove('drag-hover');
    });
  }

  private getDropTarget(element: HTMLElement): HTMLElement | null {
    // Traverse up the DOM to find the closest card element
    let current = element;
    while (current && !current.classList.contains('objective-card')) {
      current = current.parentElement as HTMLElement;
      if (!current) break;
    }
    return current;
  }

  private checkObjectivesChanged(): void {
    if (this.objectives.length !== this.originalObjectives.length) {
      this.objectivesChanged = true;
      return;
    }
    
    // Check if order or content has changed
    for (let i = 0; i < this.objectives.length; i++) {
      const current = this.objectives[i];
      const original = this.originalObjectives.find(o => o.objectiveId === current.objectiveId);
      
      if (!original || original.description !== current.description || 
          this.originalObjectives[i].objectiveId !== current.objectiveId) {
        this.objectivesChanged = true;
        return;
      }
    }
    
    this.objectivesChanged = false;
  }
}

