import { ReferenceDto } from '@ims-shared/dto/reference.dto';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, forkJoin, map, of, switchMap, Subject, takeUntil } from 'rxjs';
import { formatDate, parseDate } from '../../util/dateUtil'
import { DateAdapter } from '@angular/material/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BookingService } from '../_service/booking.service';
import { BookingGamOrderDto, BookingListDto, BookingListItemDto, CreatorListItemDto, GamLineItemErrorStatus, PageFilterOptionDto } from '@ims-shared/dto/booking.dto';
import { BookingStatus } from '@ims-shared/enum/booking-status';
import { BookingType } from '@ims-shared/enum/booking-type';
import { Router } from '@angular/router';
import { setFormattedBookingStatus } from 'src/util/enumUtil';
import { AuthService, User } from '../auth/auth.service';
import { UserRole } from '@ims-shared/enum/user-role';
import { MatSort, Sort } from '@angular/material/sort';
import { isBookingFieldDisabled } from '@ims-shared/utils/permission.util';
import { BookingField } from '@ims-shared/enum/booking-field';

export interface InventoryListingFilterProp {
  bookingType: string;
  createdBy: string;
  bookingStatus: string;
  startDate: string | Date;
  endDate: string | Date;
  searching: string;
  targetBu?: string;
}

const FILTER_PROPS_DEFAULT_VALUE = {
  bookingType: '',
  createdBy: '',
  bookingStatus: '',
  startDate: '',
  endDate: '',
  searching: '',
  targetBu: ''
};

const BookingTypesOpt = [
  'ALL', ...Object.values(BookingType)
];

const PageLimit = 10

const PAGE_SIZE_OPTS = [5, 10, 20] 
@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.css']
})
export class BookingListComponent {
  private destroy$ = new Subject<void>();
  form: FormGroup;
  filterValues!: InventoryListingFilterProp;
  sortValues: string = "";
  endDateFilterLimit: Date | null = new Date();

  userRoleList = UserRole
  user !: User

  BookingTypes = BookingTypesOpt
  creatorOptions: string[] = [];
  rootChannelOptions: ReferenceDto[] = [];
  channelRefLists: ReferenceDto[] = [];
  BookingStatus = BookingStatus;
  bookingStatusEnum = this.getDbBookingStatusEnumWithErrorStatus()
  bookingStatusOptions = ['ALL', ...Object.values(this.bookingStatusEnum)];

  displayedColumns!: string[]
  fullDisplayColumns: string[] = ['projectCode', 'advertiserName', 'bookingName', 'bookingType', 'startDate', 'endDate', 'createdBy', 'createdDate', 'bookingStatus', 'actions']; // , 'edit', 'cancel'
  dynamicDisplayedColumns: string[] = ['projectCode', 'advertiserName', 'bookingName', 'startDate', 'endDate', 'bookingStatus', 'actions']; // , 'edit', 'cancel'
  minimalColumns: string[] = ['projectCode', 'advertiserName', 'bookingName', 'startDate', 'actions'];
  dataSource = new MatTableDataSource<BookingListItemDto>();
  pageSizeOpt !: number[]
  noOfTotalBooking = 0
  nonNullBookingNo = 0
  pageOffset = 0
  previousPageIndex!: number;
  previousPageSize!: number;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort) 
  sort!: MatSort;

  constructor(
    private formBuilder: FormBuilder,
    private bookingService: BookingService,
    private dateAdapter: DateAdapter<Date>,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private authService: AuthService,
  ) {
    this.form = this.buildFormWithDefaultValue();
    this.filterValues = this.initiateFilterWithDefaultValue();
    this._initiateUserRoleCheckAndFetchBooking();
    this.dateAdapter.setLocale('en-GB');
    this.observeBreakpointChanges();
    this.previousPageIndex = 0;
    this.previousPageIndex = 0;
  }

  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
  }

  findAllBookings(offset: number = 0, limit: number = PageLimit, refresh: boolean = true, filterOption?: PageFilterOptionDto, sortOption?: string) {
    if(filterOption || sortOption){
      // console.log(`filterOption: ${JSON.stringify(filterOption)}, sortOption: ${sortOption}`);
    }
    if (!this.form.valid) {
      console.log('Form has errors:', this.form.errors);
      return
    }
    this.bookingService.findAllBookings(offset, limit, {...filterOption, sorting: sortOption}).subscribe((dto: BookingListDto) => {
      // console.log(dto.bookings);
      if (refresh){
        this.refreshDataSource(dto, offset);
      }
      else{
        this.updateDataSource(dto, offset, filterOption);
      }
      this.noOfTotalBooking = dto.count
      this.paginator.length = this.noOfTotalBooking;
      this.updatePageSizeOptions(refresh);
    })
  }

  findAllBookingsWithSorting(sortState: Sort) {
    this.sortValues = this.convertToSortCriteria(sortState); 
    this.bookingService.setPreviousSort(sortState);
    this.findBookingWithFilterChecking(true, this.paginator.pageSize);
  }

  private _initiateUserRoleCheckAndFetchBooking(): void {
    forkJoin({
      user: this.authService.getUserInfo(),
      references: this.bookingService.findAllReferences()
    }).pipe(
      switchMap(({user, references})=> {
        this.user = user
        this.channelRefLists = references.filter(item => item.refType === 'channel');
        this.subscribeToFormChanges();
        if (this.channelRefLists.length < 1 ) {
          console.error('Missing channel references: No channel references found in the list.')
          return of({dto: {bookings: [], count: 0}, paginator: null})
        }
        this.initiateRootChannelsOptions(this.channelRefLists);
        this.roleCheckWithFilterOptValue(user, this.channelRefLists)
        return this.bookingService.findCreators(user, references).pipe(
          switchMap(creators => {
            this.initiateCreatorOptions(creators);
            return combineLatest([
              this.bookingService.getPreviousFilterValue(),
              this.bookingService.getPreviousPaginator(), 
              this.bookingService.getPreviousSort()
            ]).pipe(
              switchMap(([filter, paginator, sort]) => {
                // console.log(`dto: ${dto}, filter: ${JSON.stringify(filter)}, paginator: ${JSON.stringify(paginator)}, sort: ${JSON.stringify(sort)}`)
                // Role Checking
                
                if ( paginator ){
                  
                  if (filter){
                    const rootChannelRef = this.channelRefLists.find(item => item.refName === filter.targetBu);
                    this.form.patchValue({
                      ...filter,
                      bookingType: filter.bookingType ? filter.bookingType: "ALL",
                      createdBy: filter.createdBy ? filter.createdBy: "ALL",
                      bookingStatus: filter.bookingStatus ? this.bookingStatusEnum[filter.bookingStatus] : "ALL",
                      targetBu: rootChannelRef || "" // Note: no filter on cross-Bu user
                    });
                    this.filterValues = filter
                  }
                  if (sort) {
                    this.sort.active = sort.active;
                    this.sort.direction = sort.direction;
                    this.sortValues = this.convertToSortCriteria(this.sort);
                  }
                  const filterDto = this.convertToFilterDto()
                  return this.bookingService.findAllBookings((paginator.pageIndex*paginator.pageSize), paginator.pageSize, {...filterDto, sorting: this.sortValues} ).pipe(
                    map(dto => ({ dto, paginator }))
                  );
                }
                
                return this.bookingService.findAllBookings(0, PageLimit, this.convertToFilterDto()).pipe(
                  map(dto => ({ dto, paginator: null }))
                );
              })
            );
          })
        );
      }
    )).subscribe({
      next: ({dto, paginator}) => {
        const dataSource_offset = paginator ? paginator.pageIndex*paginator.pageSize : 0
        this.updateDataSource(dto, dataSource_offset , this.convertToFilterDto());
        this.noOfTotalBooking = dto.count;
        this.paginator.length = this.noOfTotalBooking;
        if (paginator){
          this.pageSizeOpt  = PAGE_SIZE_OPTS;
          this.paginator.pageSize = paginator.pageSize;
          this.paginator.pageIndex = paginator.pageIndex;
          this.paginator.length = paginator.length;
          this.dataSource.paginator = this.paginator;
        }
        else{
          this.updatePageSizeOptions(true);
        }
      },
      error: (error) => {
        console.error(`error in _initiateUserRoleCheckAndFetchBooking(): ${error}`);
      }
    });
  }

  private refreshDataSource(dto: BookingListDto, offset: number) {
    this.dataSource.data = new Array(dto.count).fill(null);
    this.updateDataSourceItems(dto.bookings, offset);
    this.nonNullBookingNo = dto.bookings.length;
    this.bookingService.setSearchResults(dto)
  }

  private updateDataSource(dto: BookingListDto, offset: number, filterOption?: PageFilterOptionDto) {
    let updatedList = new Array (dto.count).fill(null);
    if (this.dataSource.data){
      this.dataSource.data.forEach( ( item, index ) => { 
        if (item) { 
          updatedList[index] = item; 
        } 
      });
    }
    this.dataSource.data = updatedList
    this.updateDataSourceItems(dto.bookings, offset);
    this.nonNullBookingNo = this.dataSource.data.filter(booking => booking != null).length;
    this.bookingService.setSearchResults({
      bookings: this.dataSource.data,
      count: dto.count
    })
  }

  private updateDataSourceItems(bookings: BookingListItemDto[], offset: number) {
    bookings.forEach((booking, index) => {
      this.dataSource.data[offset + index] = booking;
    });
  }

  initiateCreatorOptions(names: CreatorListItemDto[]){
    // console.log(`noOfTotalBooking: ${this.noOfTotalBooking}`)
    if (this.user.role === UserRole.SALES && this.noOfTotalBooking < 1){
      this.creatorOptions = ['ALL', this.user.name];
    }
    else{
      const creators = names.map((item) => item.creatorName ?? "" ).sort()
      this.creatorOptions = ['ALL',...new Set(creators)];
    }
  }

  private initiateRootChannelsOptions(channelRefs: ReferenceDto[]){
    if (channelRefs.length < 1 ) return 
    this.rootChannelOptions = channelRefs.filter(channel => channel.refName.toLowerCase() === channel.channelPlatform?.toLowerCase())
  }

  observeBreakpointChanges() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.displayedColumns = this.minimalColumns;
        }
        else if (result.breakpoints[Breakpoints.Small]) {
          this.displayedColumns = this.dynamicDisplayedColumns;
        } else {
          this.displayedColumns = this.fullDisplayColumns;
        }
      }
    });
  }

  buildFormWithDefaultValue() {
    return this.formBuilder.group({
      bookingType: new FormControl('ALL'),
      createdBy: new FormControl('ALL'),
      bookingStatus: new FormControl('ALL'),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      searching: new FormControl(''),
      targetBu: new FormControl<ReferenceDto| string>("")
    });
  }

  private initiateFilterWithDefaultValue() {
    return FILTER_PROPS_DEFAULT_VALUE
  }

  convertDate(elementDate: Date): string {
    return formatDate(elementDate, 'YYYY-MM-DD');
  }

  private subscribeToFormChanges(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      // console.log(`value: `, value);
      const formattedStatus = setFormattedBookingStatus(value.bookingStatus);
      this.filterValues = {
        bookingType: value.bookingType === 'ALL' ? '' : value.bookingType,
        createdBy: value.createdBy === 'ALL' ? '' : value.createdBy,
        bookingStatus: formattedStatus ? formattedStatus as string : '',
        startDate: value.startDate ? value.startDate : '',
        endDate: value.endDate ? value.endDate : '',
        searching: value.searching ? value.searching.trim().toLowerCase() : '',
        targetBu: value.targetBu ? value.targetBu.refName : ""
      }
      // console.log(`value.startDate: `, value.startDate, `value.endDate: `, value.endDate);
      // console.log(`this.filterValues: `, this.filterValues);
      this.updateCampaignDate(this.filterValues.startDate, this.filterValues.endDate)
    });
  }

  applyFilter() {
    console.log(`applyFilter for the mat-table`);
    this.pageOffset = 0;
    this.findBookingWithFilterChecking(true)
    // this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  resetStartDate() {
    this.form.get('startDate')!.reset();
    this.filterValues.startDate = ''
    this.endDateFilterLimit = null
  }

  resetEndDate() {
    this.form.get('endDate')?.reset();
    this.filterValues.endDate = ''
  }

  updateCampaignDate(form_start: string | Date | null, form_end: string | Date | null) {
    if (form_start && form_start instanceof Date) {
      const today = new Date(form_start);
      this.endDateFilterLimit = today
    }
    if (form_start && form_end) {
      if (form_start > form_end) {
        this.form.get("endDate")?.setValue(null)
        this.endDateFilterLimit = new Date(form_start)
      }
    }
  }

  onEndDateChange(event: any) {
    const endDate: any = this.form.get("endDate")!.value
    if (endDate instanceof Date) {
      const month = endDate!.getMonth();
      const year = endDate!.getFullYear();
      const day = endDate!.getDate();
      this.form.get("endDate")!.setValue(new Date(year, month, day, 23, 59))
    }
  }

  parseDate(event: Event) {
    return parseDate(event)
  }

  isInnerWidth1150() {
    return window.innerWidth < 1150
  }

  editBooking(id: number) {
    // console.log(`editBooking: `, id);
    this.router.navigate(['/booking/', id], { queryParams: { readonly: false } })
  }

  cancelBooking (booking: BookingListItemDto) {
    // console.log(`cancelBooking: `, booking.id);
    if (confirm(`Confirm to cancel the booking?\n\nProject Code: ${booking.projectCode ? booking.projectCode : "N/A"}\nAdvertiser: ${booking.advertiserName}\nCampaign Name: ${booking.bookingName}\n`)){
      this.bookingService.cancelBooking(booking.id).subscribe({
        next:(res) => {

        },
        error: (error) => {
          console.error('An error occurred in cancelBooking:', error);
          if (confirm(`Failed to cancel the booking:\n\nProject Code: ${booking.projectCode ? booking.projectCode : "N/A"}\nAdvertiser: ${booking.advertiserName}\nCampaign Name: ${booking.bookingName}\n`)){
            // window.location.reload();
          }
        },
        complete: () => {
          window.location.reload();
        }
      });
    }
  }

  getbookingStatusEnum(status: BookingStatus){
    return this.bookingStatusEnum[status as keyof typeof BookingStatus]
  }

  onPageChange(event: PageEvent) {
    // console.log(`onPageChange() before , event: ${JSON.stringify(event)}, previousPageIndex: ${this.previousPageIndex}, previousPageSize: ${this.previousPageSize}`);
    
    const pageIndexChanged = event.pageIndex !== this.previousPageIndex;
    const pageSizeChanged = event.pageSize !== this.previousPageSize;

    if (pageSizeChanged) {
      this.previousPageSize = event.pageSize;
    }

    if (pageIndexChanged || pageSizeChanged || this.sortValues) {
      this.previousPageIndex = event.pageIndex;
      this.pageOffset = event.pageIndex * event.pageSize;
      this.findBookingWithFilterChecking(false, event.pageSize);
    }
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.length = this.noOfTotalBooking;
    this.bookingService.setPreviousPaginator(this.paginator)
    // console.log(`onPageChange() after , event: ${JSON.stringify(event)}, previousPageIndex: ${this.previousPageIndex}, previousPageSize: ${this.previousPageSize}`);
  }

  findBookingWithFilterChecking(refresh: boolean, pageLimit = this.paginator.pageSize ?? PageLimit) {
    if(refresh){
      this.resetPaginator();
    }
    const filterDto = this.convertToFilterDto()
    if (filterDto){
      this.findAllBookings(this.pageOffset, pageLimit, refresh, filterDto, this.sortValues);
    } 
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.previousPageIndex = 0;
      this.pageOffset = 0;
    }
    this.bookingService.setPreviousPaginator(this.paginator);
  }

  updatePageSizeOptions(refresh: boolean) {
    let newPageSizeOpt = PAGE_SIZE_OPTS // .filter(size => size * 2 < dataLength);
    this.pageSizeOpt  = newPageSizeOpt.length > 0 ? newPageSizeOpt : [10];
    // console.log(`newPageSizeOpt: ${newPageSizeOpt}, dataLength: ${dataLength}`);
    // console.log(`this.pageSizeOpt: ${this.pageSizeOpt}`);
    this.previousPageSize = this.pageSizeOpt.length ? this.pageSizeOpt[1] : 10;
    if (refresh && !this.sortValues) {
      this.paginator.pageSize = this.pageSizeOpt[1];
    }
    else{
      this.paginator.pageSize = this.paginator.pageSize ?? 10
    }
    this.paginator.length = this.noOfTotalBooking;
    // console.log(`refresh: ${refresh}, previousPageSize: ${this.previousPageSize}, pageSizeOpt: ${this.pageSizeOpt}, dataLength: ${dataLength}, newPageSizeOpt: ${newPageSizeOpt}`);
    this.dataSource.paginator = this.paginator; 
  }

  disableEditBtnCheck(booking: BookingListItemDto){
    if (this.user.role === undefined){
      return true
    }
    if (booking.status === BookingStatus.PENDING_TM_RESPONSE || booking.status === BookingStatus.PENDING_TM_RESPONSE_CONFIRMATION || booking.status === BookingStatus.PENDING_APPROVAL){
      return false
    }
    else if (booking.status === BookingStatus.APPROVED){
      if (this.user.role === UserRole.SALES || this.user.role === UserRole.SALES_ADMIN ){
        return true
      }
      return false
    }
    else if (booking.status === BookingStatus.CANCELLED || booking.status === BookingStatus.CLOSED){
      return true
    }
    return true
  }

  disableCancelBtnCheck(booking: BookingListItemDto){
    if (this.user.role === undefined){
      return true
    }
    return isBookingFieldDisabled(booking.status!, this.user.role, BookingField.CANCEL_AND_COPY);
  }

  convertToFilterDto(){
    const filterValues = this.roleCheckWithFilterOptValue(this.user, this.channelRefLists);
    if (!filterValues) return
    this.bookingService.setPreviousFilter(filterValues);
    return {
      isTm: filterValues.bookingType === BookingType.tm ? true : filterValues.bookingType === BookingType.nonTm ? false : undefined,
      status: filterValues.bookingStatus ? filterValues.bookingStatus as BookingStatus: undefined,
      creatorName: filterValues.createdBy ? filterValues.createdBy: undefined,
      startDate: filterValues.startDate ? formatDate(filterValues.startDate as Date, 'YYYY-MM-DD') : undefined,
      endDate: filterValues.endDate ? formatDate(filterValues.endDate as Date, 'YYYY-MM-DD'): undefined,
      searching: filterValues.searching ? filterValues.searching: undefined,
      targetBu: filterValues.targetBu
    }
  }

  roleCheckWithFilterOptValue(user: User, channelRefs: ReferenceDto[]){
    if (!user || channelRefs.length < 1 ) {
      this.form.setErrors({
        'missingUserInfoOrChannelReferences': {
          message: 'Form is invalid',
          reason: 'Missing user info/ channel references for roleCheckWithFilterOptValue'
        }
      });
      return 
    }
    const userTeamRefs = channelRefs.find(item =>  item.refName.toLowerCase() === user.team.toLowerCase())
    if (user.role === UserRole.TM) {
      this.form.get('bookingType')?.setValue('TM');
      this.form.get('bookingType')?.disable({ onlySelf: true });
      this.filterValues = {
        ...this.filterValues,
        bookingType: BookingType.tm
      };
    }
    else if (user.role === UserRole.SALES){
      this.form.get('createdBy')?.setValue(user.name);
      this.filterValues = {
        ...this.filterValues,
        createdBy: user.name
      };
    }
    else if (user.role === UserRole.SALES_ADMIN){
      const targetBuControl = this.form.get("targetBu")
      if (!user.crossDept){
        if (userTeamRefs && Object.keys(userTeamRefs).length > 0){
          const nonCrossBuChannelRef = channelRefs.filter(item => item!.refName === userTeamRefs!.channelPlatform && item!.channelPlatform === item!.refName)
          // console.log("nonCrossBuChannelRef", nonCrossBuChannelRef)
          if (nonCrossBuChannelRef.length === 1 && nonCrossBuChannelRef[0]){
            targetBuControl?.setErrors(null);
            targetBuControl?.setValue(nonCrossBuChannelRef[0]);
            targetBuControl?.disable({ onlySelf: true });
            this.filterValues = {
              ...this.filterValues,
              targetBu: nonCrossBuChannelRef[0].channelPlatform
            };
          }
          else{
            targetBuControl?.setErrors({ 'nonCrossBuChannelRefNotFound': true });
          }
        }
        else{
          targetBuControl?.setErrors({ 'userTeamRefsNotFound': true });
        }
        // console.log(`checking sale admin form value in roleCheckWithFilterOptValue`, this.form.value)
      }
      else{
        const targetBuValue = targetBuControl?.value?.refName ?? '';
        this.filterValues = {
          ...this.filterValues,
          targetBu: targetBuValue
        };
      }
    }
    return this.filterValues
  }

  convertToSortCriteria(sortState:Sort){
    let tableName = sortState.active;
    let fieldName = ""
    if (tableName === "bookingStatus") {
      fieldName = "status"
    }
    else if (tableName === "bookingType") {
      fieldName = "isTm"
    }
    else {
      fieldName = tableName
    }
    return sortState.direction ? `${fieldName},${(sortState.direction as string).toUpperCase() as 'ASC' | 'DESC'}`: ``
      
  }

  showBookingStatusErrorToolTip(detail: BookingGamOrderDto){
    if (!detail || !detail.lineItemDetails) return ""
    let list = detail.lineItemDetails.map(item => item.errorStatus)
    const errorStatisList = list.filter(
      (item): item is GamLineItemErrorStatus => item !== null && item !== undefined
    );
    if (errorStatisList.includes(GamLineItemErrorStatus.ERROR_BOTH)){
      return "Error in creating / updating line item and preview line item!! Please try again"
    }
    const tooltips = errorStatisList.map(item => {
      if (item === GamLineItemErrorStatus.ERROR_LINE_ITEM){
        return "Error in creating / updating line item!!"
      }
      else if (item === GamLineItemErrorStatus.ERROR_PREVIEW_LINE_ITEM){
        return "Error in creating preview line item!!"
      }
      else{
        return "Error in Approval!!"
      }
    })
    return tooltips.join('\n') +" Please try again"
  }

  getDbBookingStatusEnumWithErrorStatus(): { [key: string]: string } {
    const descriptions: { [key: string]: string } = {
        [BookingStatus.PENDING_TM_RESPONSE]: 'Pending TM Response',
        [BookingStatus.PENDING_TM_RESPONSE_CONFIRMATION]: 'Pending TM Response Confirmation',
        [BookingStatus.PENDING_APPROVAL]: 'Pending Approval',
        [BookingStatus.APPROVED]: 'Approved',
        [BookingStatus.CANCELLED]: 'Cancelled',
        [BookingStatus.CLOSED]: 'Closed',
        [BookingStatus.ERROR_GAM]: 'Error in GAM'
    };
    return descriptions;
  }

  parseCreatedDate(createdDate: string) {
    return formatDate(new Date(createdDate), 'YYYY-MM-DD HH:mm');
  }

  navigateViewPage(id: number) {
    // console.log('id in navigateViewPage: ', id)
    this.bookingService.setPreviousPaginator(this.paginator)
    this.router.navigate(['/booking', id]);
  }
}


