import { Injectable } from "@angular/core";
import { ApiService } from "./api.services";
import { ObjectiveDto, UpdateObjectiveDto } from "@ims-shared/dto/config.dto";
import { catchError, map, Observable, throwError } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ObjectiveService {

    constructor(
        private apiService: ApiService
    ) {}

    findObjectives(): Observable<ObjectiveDto[]> {
        return this.apiService.get<ObjectiveDto[]>('/config/objective').pipe(
            catchError(error => {
                return throwError(() => new Error(error));
            })
        )
    }

    createObjective(objective: ObjectiveDto): Observable<ObjectiveDto> {
        return this.apiService.post<ObjectiveDto>('/config/objective', objective).pipe(
            catchError(error => {
                return throwError(() => new Error(error));
            })
        )
    }

    updateObjective(objective: UpdateObjectiveDto[]): Observable<ObjectiveDto[]> {
        return this.apiService.put<ObjectiveDto[]>('/config/objective', objective).pipe(
            catchError(error => {
                return throwError(() => new Error(error));
            })
        )
    }

    deleteObjective(id: number): Observable<void> {
        return this.apiService.delete<void>(`/config/objective/${id}`).pipe(
            catchError(error => {
                return throwError(() => new Error(error));
            })
        )
    }
}