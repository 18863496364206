import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberFormatter]'
})
export class NumberFormatterDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const sanitizedValue = value.replace(/\D/g, '');
    const formattedValue = this.formatNumberWithCommas(sanitizedValue);
    this.el.nativeElement.value = formattedValue;
  }

  private formatNumberWithCommas(value: string): string {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}